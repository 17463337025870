import React, { ComponentProps, PropsWithChildren, ReactNode } from 'react'
import { Pill } from '@design-system'

export type ExperienceCategoryVariant = 'activity' | 'electives' | 'expedition' | 'humanities' | 'independent_study' | 'language_learning_goal' | 'learning_goal' | 'math_learning_goal' | 'stem' | 'track' | 'coworking'

interface PillAllowedProps extends Pick<ComponentProps<typeof Pill>, 'rounded' | 'variant' | 'color' | 'disabled'> { }

interface VariantProps extends PillAllowedProps {
  childrenFn: (props: Omit<PillExperienceCategoryProps, 'variant'>) => ReactNode
}

const defaultProps: VariantProps = {
  childrenFn: ({ variant }: PillExperienceCategoryProps) => <Pill.Value>{variant}</Pill.Value>,
}

const variants: Record<ExperienceCategoryVariant, VariantProps> = {
  activity: {
    childrenFn: () => <Pill.Value>Activity</Pill.Value>,
    color: 'cyan-5',
  },
  electives: {
    childrenFn: () => <Pill.Value>Electives</Pill.Value>,
    color: 'orange-5',
  },
  expedition: {
    childrenFn: () => <Pill.Value>Expedition</Pill.Value>,
    color: 'pink-5',
  },
  humanities: {
    childrenFn: () => <Pill.Value>Humanities</Pill.Value>,
    color: 'turquoise-5',
  },
  independent_study: {
    childrenFn: () => <Pill.Value>ISE</Pill.Value>,
    color: 'purple-5',
  },
  language_learning_goal: {
    childrenFn: () => <Pill.Value>Language learning</Pill.Value>,
    color: 'blue-5',
  },
  learning_goal: {
    childrenFn: () => <Pill.Value>Learning goal</Pill.Value>,
    color: 'notice-5',
  },
  math_learning_goal: {
    childrenFn: () => <Pill.Value>Math</Pill.Value>,
    color: 'purple-5',
  },
  stem: {
    childrenFn: () => <Pill.Value>STEM</Pill.Value>,
    color: 'green-5',
  },
  track: {
    childrenFn: () => <Pill.Value>Track</Pill.Value>,
    color: 'green-5',
  },
  coworking: {
    childrenFn: () => <Pill.Value>Coworking</Pill.Value>,
    color: 'yellow-5',
  },
}

interface PillExperienceCategoryProps extends PropsWithChildren {
  variant?: ExperienceCategoryVariant
}

/**
 * PillExperienceCategory display a pill with student progress
 */
export const PillExperienceCategory = (props: PillExperienceCategoryProps) => {
  const { childrenFn, ...pillProps } = variants[props.variant] ?? defaultProps
  return (
    <Pill {...pillProps}>
      {childrenFn({ ...props })}
    </Pill>
  )
}
