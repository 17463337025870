import React from 'react'
import PropTypes from 'prop-types'
import { Button, Icon, useFetcher } from '@design-system'

export const StudentDetach = ({ studentId }) => {
  const fetcher = useFetcher()
  const isLoading = fetcher.state !== 'idle'
  return (
    <fetcher.Form method="post">
      <input type="hidden" name="_action" value="detach_student" />
      <input type="hidden" name="student_id" value={studentId} />
      <Button color="danger" size="xs" type="submit" loading={isLoading} disabled={isLoading}>
        <Icon name="minus" size="xs" color='white' />
      </Button>
    </fetcher.Form>
  )
}
StudentDetach.propTypes = { 
  studentId: PropTypes.number.isRequired,
}
