import React from 'react'
import axios from 'axios'
import { Outlet, useLoaderData, useLocation, useParams } from 'react-router'
import { Button, Icon, Typography } from '@design-system'
import SoraLink from '@components/link'

interface LoaderData {
  views: { value: string, label: string }[]
  experience: {
    title: string
  }
}

async function loader({ request, params }) {
  const searchParams = new URL(request.url).searchParams
  const { data } = await axios.get(`/backoffice/facilitate/${params.type}/${params.experienceId}/attendance?${searchParams.toString()}`)
  return data
}

function Element() {
  const { views, experience } = useLoaderData() as LoaderData
  const location = useLocation()
  const currentView = location.pathname.includes('aggregated') ? 'aggregated' : 'default'
  const backUrl = location.pathname.split('/attendance')[0]
  return (
    <div className="space-y-8">
      <header className="flex gap-4">
        <Button asChild size="sm" variant="outlined" color="soft">
          <SoraLink to={backUrl}>
            <Icon name="arrow-left" size="sm" />
          </SoraLink>
        </Button>
        <div className="space-y-1">
          <Typography variant="heading-3" weight="bold">Attendance</Typography>
          <Typography>{experience.title}</Typography>
        </div>
      </header>
      <nav className="space-x-2">
        {views.map(({ value, label }) => (
          <Button key={value} color={value === currentView ? 'dark' : 'soft'} size="sm">
            <SoraLink to={value} replace>{label}</SoraLink>
          </Button>
        ))}
      </nav>
      <Outlet />
    </div>
  )
}

export const FacilitateExperienceAttendanceRoute = {
  loader,
  Element,
}
