import React from 'react'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'
import { faChevronLeft, faChevronRight, faCheck, faFlag, faWarning, faCheckDouble } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import StudentsSubmissionStatus from '@components/facilitate/feedback/students-submission-status'


const StudentList = ({ data, selectedStudentId, showStudents, handleToggleShowStudents }) => {
  return (
    <div className={`flex flex-col group transition-all duration-300 bg-white border border-gray-40 rounded-xl px-3 pt-12 pb-6 ${showStudents ? 'min-w-[12.5rem] w-[25rem]' : 'min-w-[70px] w-[70px] cursor-pointer'} space-y-2 relative`} onClick={!showStudents ? handleToggleShowStudents : null}>
      <ReactTooltip delayShow={500} />
      <div className="absolute top-5 transform translate-x-1/2 right-0 hidden hover:block group-hover:block" data-tip={`${showStudents ? 'Collapse' : 'Show'} student name list`}>
        <button onClick={handleToggleShowStudents} className="w-7 h-7 rounded-full bg-white border border-gray-60 text-gray-60 hover:bg-gray-30">
          <FontAwesomeIcon icon={showStudents ? faChevronLeft : faChevronRight} size="sm" />
        </button>
      </div>
      <StudentsSubmissionStatus
        showStudents={showStudents}
        color='blue'
        icon={faCheck}
        title='UNASSESSED'
        list={data.unassessed}
        listKey='unassessed'
        selectedId={selectedStudentId}
      />
      <StudentsSubmissionStatus
        showStudents={showStudents}
        color='red'
        icon={faFlag}
        title='FLAGGED'
        list={data.flagged}
        listKey='flagged'
        selectedId={selectedStudentId}
      />
      <StudentsSubmissionStatus
        showStudents={showStudents}
        color='yellow'
        icon={faWarning}
        title='NO SUBMISSIONS'
        list={data.not_submitted}
        listKey='not_submitted'
        selectedId={selectedStudentId}
      />
      <StudentsSubmissionStatus
        showStudents={showStudents}
        color='green'
        icon={faCheckDouble}
        title='ASSESSED'
        list={data.assessed}
        listKey='assessed'
        selectedId={selectedStudentId}
      />
    </div>
  )
}
StudentList.displayName = 'StudentList'
StudentList.propTypes = {
  data: PropTypes.object,
  selectedStudentId: PropTypes.string,
  showStudents: PropTypes.bool.isRequired,
  handleToggleShowStudents: PropTypes.func,
}

export default StudentList
