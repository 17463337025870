import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { RenderedSlate } from '@components/forms/slate-textarea'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar } from '@fortawesome/free-regular-svg-icons'
import { faAnglesDown, faAnglesUp } from '@fortawesome/free-solid-svg-icons'
import useSWR from 'swr'

const TaskDetail = ({ task_id, forceExpand = false, taskData = null, className = '' }) => {
  let { data: { result: task } = {} } = useSWR(task_id && !taskData?.result ? `/tasks/${task_id}/detail` : null)
  if (!task && taskData?.result) {
    task = taskData.result
  }
  const [expanded, setExpanded] = React.useState(false)
  const bodyRef = useRef(null)

  const formatDueDate = (value) => {
    const date = new Date(value)
    return `${date.toLocaleString([], {
      month: 'long',
    })} ${date.toLocaleString([], {
      day: 'numeric',
    })} • ${date.toLocaleString([], { year: 'numeric' })} • ${date.toLocaleString([], { hour: 'numeric', minute: 'numeric' })}`
  }

  if (!task) return null

  return (
    <div className={`rounded-xl bg-gray-10 ${className}`}>
      <div className="flex flex-col p-8">
        <span className="opacity-50 text-sm">{task.Theme.title}</span>
        <div className="flex flex-row gap-3 text-2xl font-bold items-center pb-4">
          <div className="py-3">
            <div className="bg-blue-70 h-6 rounded-full w-1" />
          </div>
          <span>{task.title}</span>
        </div>
        <div
          className={`relative pb-6 ${(expanded || forceExpand) || (bodyRef.current?.clientHeight || 45) < 45
            ? ''
            : 'overflow-hidden h-44'
            }`}
        >
          <div ref={bodyRef}>
            <RenderedSlate value={task.body} />
          </div>
          {forceExpand || (bodyRef.current?.clientHeight || 45) >= 45 && (
            <div className="flex flex-col absolute bottom-0 right-0 left-0 gap-0">
              <div className="bg-gradient-to-t from-gray-10 h-6"></div>
              <div className=" flex flex-row bg-gray-10 text-center items-center">
                <div className=" flex-auto h-px rounded-full bg-gray-40" />
                <button
                  className="bg-gray-10 px-5 py-px text-sm"
                  onClick={() => {
                    setExpanded(!expanded)
                  }}
                >
                  {expanded ? (
                    <FontAwesomeIcon icon={faAnglesUp} />
                  ) : (
                    <FontAwesomeIcon icon={faAnglesDown} />
                  )}
                </button>
                <div className="flex-auto h-px rounded-full bg-gray-40" />
              </div>
            </div>
          )}
        </div>
        <div className="flex flex-row items-center gap-3 pt-2 opacity-70">
          <FontAwesomeIcon icon={faCalendar} />
          <span>Due {formatDueDate(task.due_at)}</span>
        </div>
      </div>
    </div >
  )
}
TaskDetail.displayName = 'TaskDetail'
TaskDetail.propTypes = {
  task_id: PropTypes.string,
  forceExpand: PropTypes.bool,
  taskData: PropTypes.object,
  className: PropTypes.string,
}

export default TaskDetail
