import React, { Fragment, useEffect } from "react"
import axios from "axios"
import { Outlet, redirect, useActionData, useLoaderData, useParams, useSubmit } from "react-router-dom"
import isSoraHomeLink from "@utils/isSoraHomeLink"
import openExternalLink from "@utils/openExternalLink"
import serializeFormData from "@utils/serializeFormData"
import Notification from "@components/notifications/notification"
import type { Notification as NotificationType } from "@components/notifications/types"
import { useToast } from "@hooks/useToast"

type ActionData = {
  redirectOnNewTab: string,
  toast?: {
    message: string,
    appearance: 'success' | 'error',
  }
}

export default function Employee$UserIdNotifications$NotificationTypeRoute() {
  const submit = useSubmit()
  const actionData = useActionData() as ActionData
  const { notification_id: notificationId } = useParams()
  const { notifications } = useLoaderData() as { notifications: Array<NotificationType> }

  useToast(actionData)
  useEffect(() => {
    if (actionData?.redirectOnNewTab) {
      openExternalLink(actionData?.redirectOnNewTab)
    }
  }, [actionData?.redirectOnNewTab])

  return (
    <div className="grid lg:grid-cols-2 lg:gap-8">
      <Notification.List hasNotifications={notifications.length > 0}>
        {notifications.map((notification) => (
          <Fragment key={notification.id}>
            <Notification.Item
              onClick={() => {
                submit({ notificationId: String(notification.id), actionName: 'readNotification', redirectUrl: notification.redirect_url, notificationReadStatus: notification.read ? 'read' : 'unread', notificationContent: JSON.stringify(notification.content) }, { method: 'post' })
              }}
              onDeleteNotification={() => submit({ notificationId: String(notification.id), actionName: 'deleteNotification' }, { method: 'post' })}
              isSelected={Number(notificationId) === Number(notification.id)}
              {...notification}
            />
            <div className="lg:hidden">
              {notification.content && notificationId === String(notification.id) ? <Outlet /> : null}
            </div>
          </Fragment>
        ))}
      </Notification.List >
      <div className="hidden lg:block">
        {!notificationId ? (
          <h2 className="text-center">
            No notifications selected
          </h2>
        ) : notifications.map((notification) => (
          notification.content && notificationId === String(notification.id) ? <Outlet key={notification.id + '_mobile'} /> : null
        ))}
      </div>
    </div>
  )
}

export async function loader({ params }) {
  const { user_id: userId, notification_type: notificationType } = params || {}
  try {
    const { data: notifications } = await axios.get(`/notifications?user_id=${userId}&type=${notificationType}`)
    return { notifications }
  } catch (error) {
    console.error(error)
  }
}

type NotificationFormData = {
  actionName: 'deleteNotification' | 'readNotification',
  notificationId: string,
  notificationReadStatus: 'read' | 'unread', // NotificationFormData type must be a { [key: string]: string } according to SubmitTarget type. That's why we can't use booleans.
  redirectUrl?: string,
  notificationContent: string,
}

export async function action({ request }) {
  const formData = await request.formData()
  const { actionName, ...jsonData } = serializeFormData(formData) as NotificationFormData

  if (actionName === 'deleteNotification') {
    try {
      await axios.delete(`/notifications/${jsonData.notificationId}`)
      return {
        ok: true,
        toast: {
          appearance: 'success',
          message: 'Notification deleted.',
        }
      }
    } catch (error) {
      return {
        ok: false,
        toast: {
          appearance: 'error',
          message: `An error happened while deleting your notification.`,
        },
        error
      }
    }
  }

  if (actionName === 'readNotification') {
    try {
      if (jsonData.notificationReadStatus === 'unread') await axios.put(`/notifications/${jsonData.notificationId}/read`)

      try {
        if (Object.keys(JSON.parse(jsonData.notificationContent)).length)
          return redirect(`details/${jsonData.notificationId}`)
      } catch (error) { }
      return isSoraHomeLink(jsonData.redirectUrl)
        ? redirect(jsonData.redirectUrl)
        : {
          ok: true,
          redirectOnNewTab: jsonData.redirectUrl,
        }
    } catch (error) {
      return {
        ok: false,
        toast: {
          appearance: 'error',
          message: `An error happened while reading your notification.`,
        },
        error
      }
    }
  }
}
