import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@design-system'

/**
 * Function to generate the pagination array
 * @param {number} totalPages - Total number of pages
 * @param {number} currentPage - Current selected page
 * @returns {Array} - Array of pages to be displayed
 */
function generatePagination(totalPages, currentPage) {
  let pages = [1]

  if (totalPages <= 1) return [1]

  if (totalPages > 7) {
    if (currentPage <= 4) {
      for (let i = 2; i <= 7; i++) {
        pages.push(i)
      }
    } else if (currentPage >= totalPages - 3) {
      for (let i = totalPages - 6; i < totalPages; i++) {
        pages.push(i)
      }
    } else {
      for (let i = currentPage - 3; i <= currentPage + 3; i++) {
        if (i > 1 && i < totalPages) {
          pages.push(i)
        }
      }
    }
  } else {
    for (let i = 2; i <= totalPages; i++) {
      pages.push(i)
    }
  }

  if (!pages.includes(totalPages)) {
    pages.push(totalPages)
  }

  return pages
}

export default function Paginator({ formId, totalPages, currentPage }) {
  const pagination = generatePagination(totalPages, Number(currentPage))
  return (<div>
    {
      pagination.map((page, index) =>
        <span key={page} className={pagination[index + 1] !== page + 1 ? 'pr-4' : 'pr-1'}>
          <Button
            variant={page === Number(currentPage) ? 'contained' : 'outlined'}
            type="submit"
            form={formId}
            name="page"
            value={page}
            data-testid={`paginator-page-${page}-btn`}
          >
            {page}
          </Button>
        </span>
      )
    }
  </div>)
}

Paginator.propTypes = {
  totalPages: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  formId: PropTypes.string,
}
