import React from 'react'
import { ActionFunctionArgs, LoaderFunctionArgs, useLoaderData, useNavigate } from 'react-router-dom'
import axios from 'axios'
import utcToZonedTime from 'date-fns-tz/utcToZonedTime'
import serializeFormData from '@utils/serializeFormData'
import { unstable_Dialog as Dialog, Typography, Button, Combobox, unstable_Datepicker as Datepicker, useFetcher } from '@design-system'
import { TextField } from '@designsystem'
import SlateTextarea from '@components/forms/slate-textarea'
import SoraLink from '@components/link'

async function loader({ params }: LoaderFunctionArgs) {
  const { data } = await axios.get(`/backoffice/employee/students/${params.student_id}/notes/form/${params.noteId ?? ''}`)
  return data
}

async function action({ request, params }: ActionFunctionArgs) {
  const formData = await request.formData()
  const { data } = await axios.post(`/backoffice/employee/students/${params.student_id}/notes/form/${params.noteId ?? ''}`, serializeFormData(formData))
    .catch(error => {
      return {
        data: {
          toast: {
            appearance: 'error',
            message: error.response.data.error.message
          },
          errors: error.response.data.errors,
        },
      }
    })
  return data
}

interface LoaderDataProps {
  title: string
  canDelete: boolean
  initialValues: {
    title: string
    type: string
    date: string
    content: any[]
  }
  noteTypes: Array<{ value: string, label: string }>
}

function Element() {
  const data = useLoaderData() as LoaderDataProps
  const navigate = useNavigate()
  const fetcher = useFetcher()
  const formErrors = fetcher.data?.errors || []
  const initialDate = utcToZonedTime(data.initialValues.date, 'UTC')
  return (
    <Dialog
      open={true}
      onOpenChange={() => navigate(-1)}
    >
      <Dialog.Content className="px-0 max-w-[700px]">
        <Dialog.Header className="flex border-b border-neutral-200 px-6 pb-6">
          <Typography variant="subheadline" weight="bold">
            {data.title}
          </Typography>
        </Dialog.Header>
        <fetcher.Form method="POST" noValidate>
          <div className="grid lg:grid-cols-2 gap-4 p-6">
            <div className="lg:col-span-2">
              <TextField autoFocus name="title" label="Title" defaultValue={data.initialValues.title} required error={formErrors.find(err => err.fieldName === 'title')?.message} />
            </div>
            <Combobox name="type" label="Type" required defaultValue={data.initialValues.type} search={<Combobox.Search placeholder="Search..." />} error={formErrors.find(err => err.fieldName === 'type')?.message}>
              {data.noteTypes?.map(({ value, label }) => (
                <Combobox.Item key={value} value={value}>{label}</Combobox.Item>
              ))}
            </Combobox>
            <div className="space-y-1">
              <label htmlFor="date" className="text-sm font-sans font-normal text-gray-100 dark:text-gray-20">Date <span className='text-danger-50'>*</span></label>
              <Datepicker
                name='date'
                /* @ts-ignore: Unreachable code error */
                defaultSelected={initialDate}
              />
              {formErrors.find(err => err.fieldName === 'date')?.message && <Typography variant="footnote" color="danger">{formErrors.find(err => err.fieldName === 'date')?.message}</Typography>}
            </div>
            <div className="lg:col-span-2">
              <div className="space-y-1">
                <label htmlFor="date" className="text-sm font-sans font-normal text-gray-100 dark:text-gray-20">Content <span className='text-danger-50'>*</span></label>
                <SlateTextarea
                  /* @ts-ignore: Unreachable code error */
                  id="content"
                  name="content"
                  value={data.initialValues.content}
                  placeholderText="Add context..."
                  className="h-36"
                />
                {formErrors.find(err => err.fieldName === 'content')?.message && <Typography variant="footnote" color="danger">{formErrors.find(err => err.fieldName === 'content')?.message}</Typography>}
              </div>
            </div>
          </div>
          <Dialog.Footer className="px-6 flex justify-end">
            {data.canDelete && (
              <div className="grow">
                <Button variant="outlined" color="danger" name="_action" value="delete" type="submit" loading={fetcher.state === 'submitting' && fetcher.formData.get('_action') === 'delete'}>
                  Delete
                </Button>
              </div>
            )}
            <Button asChild variant="outlined">
              <SoraLink to=".." replace>
                Cancel
              </SoraLink>
            </Button>
            <Button type="submit" loading={fetcher.state === 'submitting' && !fetcher.formData.get('_action')}>Save</Button>
          </Dialog.Footer>
        </fetcher.Form>
      </Dialog.Content>
    </Dialog>
  )
}

export const EmployeeStudentNotesFormRoute = {
  loader,
  action,
  Element,
}
