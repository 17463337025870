import React from 'react'
import axios from 'axios'
import { useLoaderData, ActionFunctionArgs, useParams } from 'react-router-dom'
import serializeFormData from '@utils/serializeFormData'
import { Alert, Button, Icon, useFetcher } from '@design-system'
import SoraLink from '@components/link'

interface LoaderData {
  alert?: {
    variant?: 'notice'
    title: string
    description?: string
    action?: string
    label?: string
  },
  subjects: {
    id: number
    title: string
    isInvalid: boolean
  }[]
}

export async function loader({ request, params }) {
  const cycleId = new URL(request.url).searchParams.get('cycle_id')
  const { data } = await axios.get(`/backoffice/employee/students/${params.student_id}/program${cycleId ? `?cycle_id=${cycleId}` : ''}`)
  return data
}

async function action({ request, params }: ActionFunctionArgs) {
  const formData = await request.formData()
  const { data } = await axios.post(`/backoffice/employee/students/${params.student_id}/program`, serializeFormData(formData))
    .catch(error => {
      return {
        data: {
          toast: {
            appearance: 'error',
            message: error.response.data.error.message
          },
          errors: error.response.data.errors,
        },
      }
    })
  return data
}

function Element() {
  const { alert, subjects } = useLoaderData() as LoaderData
  const fetcher = useFetcher()
  const { subjectId } = useParams()
  return (
    <div className="space-y-8">
      {alert && (
        <Alert variant={alert.variant}>
          <Alert.Title>{alert.title}</Alert.Title>
          {alert.description && (
            <Alert.Description>{alert.description}</Alert.Description>
          )}
          {alert.action && (
            <Alert.Actions>
              <fetcher.Form method="post">
                <input type="hidden" name="_action" value={alert.action} />
                <Button size='xs' type="submit">
                  {alert.label}
                </Button>
              </fetcher.Form>
            </Alert.Actions>
          )}
        </Alert>
      )}
      <nav className="flex gap-2 flex-wrap">
        {subjects.map(subject => (
          <Button key={subject.id} size="xs" color={subject.id === parseInt(subjectId) ? "dark" : "soft"} asChild>
            <SoraLink to={`../program/${subject.id}`}>
              {subject.title}
              {subject.isInvalid && <Icon name="alert-triangle" size="xs" />}
            </SoraLink>
          </Button>
        ))}
      </nav>
    </div>
  )
}

export const EmployeeStudentProgramRoute = {
  Element,
  loader,
  action
}


