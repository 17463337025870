import React from 'react'
import axios from 'axios'
import { Icon, IconProps, Typography } from '@design-system'
import { useLoaderData } from 'react-router-dom'
import { format } from 'date-fns'
import SoraLink from '@components/link'

async function loader({ request, params }) {
  const searchParams = new URL(request.url).searchParams
  const { data } = await axios.get(`/backoffice/facilitate/${params.type}/${params.experienceId}/attendance/aggregated?${searchParams.toString()}`)
  return data
}

export type Status = 'Present' | 'Unexcused' | 'Excused' | 'Late' | 'Upcoming' | 'Insufficient'

interface StatusIconProps {
  name: IconProps['name']
  className?: string
}

const STATUSES: Record<Status, StatusIconProps> = {
  Present: { name: 'check-rec-filled', className: 'text-green-50 dark:text-green-30' },
  'Unexcused': { name: 'minus-rec-filled', className: 'text-danger-50 dark:text-danger-30' },
  'Excused': { name: 'minus-rec-filled', className: 'text-gray-50 dark:text-gray-30' },
  'Insufficient': { name: 'minus-rec-filled', className: 'text-yellow-50 dark:text-yellow-30' },
  'Late': { name: 'clock-filled', className: 'text-yellow-50 dark:text-yellow-30' },
  'Upcoming': { name: 'minus-octagon', className: 'text-alpha/30' },
}

type LoaderData = {
  days: {
    timestampz: string,
    isUpcoming: boolean
  }[],
  students: {
    studentId: number,
    studentName: string,
    dailyStatuses: Record<string, Status>
  }[]
}

function Element() {
  const { days, students } = useLoaderData() as LoaderData
  return (
    <div className="bg-white dark:bg-gray-100 border dark:border-gray-90 rounded">
      <div className="flex gap-4 p-4 border-b dark:border-gray-90">
        <Typography variant="heading-6" weight="bold" className="grow">Attendance</Typography>
        {Object.entries(STATUSES).map(([status, { name, className }]) => (
          <div className="flex items-center gap-2" key={status}>
            <Icon name={name} className={className} />
            <Typography variant="callout" weight="bold">{status}</Typography>
          </div>
        ))}
      </div>
      <table className="w-full">
        <thead>
          <tr className="border-b dark:border-gray-90">
            <th className="text-left pl-3">
              <Typography variant="callout" weight="bold">Student name</Typography>
            </th>
            {days.map(d => (
              <th className="text-center p-3" key={d.timestampz}>
                <Typography variant="callout" weight="bold" color={d.isUpcoming ? "tertiary" : undefined}>
                  {format(new Date(d.timestampz), 'EEE')},<br />
                  {format(new Date(d.timestampz), 'MMM d')}
                </Typography>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {students.map(student => (
            <tr key={student.studentId} className="border-b dark:border-gray-90 last:border-none hover:bg-gray-5 dark:hover:bg-gray-95">
              <td className="pl-3">
                <SoraLink to={`/employee/students/${student.studentId}/overview`} className='hover:underline'>
                  {student.studentName}
                </SoraLink>
              </td>
              {days.map(d => (
                <td className="py-3 text-center" key={`${d.timestampz}`}>
                  <Icon {...STATUSES[student.dailyStatuses[d.timestampz]]} />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export const FacilitateExperienceAttendanceAggregatedRoute = {
  loader,
  Element,
}
