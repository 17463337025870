{/* prettier-ignore */ }
import { Toaster, unstable_TooltipBase as TooltipBase } from '@design-system'
import 'regenerator-runtime/runtime'
import React from 'react'
import * as Sentry from '@sentry/react'
import axios from 'axios'
import { PageTitleProvider } from '@hooks/usePageTitle'
import { ConfirmModalProvider } from '@hooks/useConfirmModal/provider'
import PropTypes from 'prop-types'
import { SWRConfig } from 'swr'
import { captureException } from '@sentry/react'
import { Route, createBrowserRouter, createRoutesFromElements, RouterProvider } from 'react-router-dom'

import SignInRoute, { loader as signInLoader } from '@pages/signin'
import GuardianSigninRoute, { loader as guardianSignInLoader } from '@pages/signin/guardian'
import NewPassword, {
  loader as newPasswordRouteLoader,
  action as newPasswordRouteAction,
} from '@routes/password/new'
import RecoverPassword from '@pages/password/recover'
import TemplateExpeditionLibrary from '@routes/template/library'
import { loader as templateExpeditionLibraryLoader } from '@routes/template/library/loader'
import { action as templateExpeditionLibraryAction } from '@routes/template/library/action'
import PaceAndProgressRoute, {
  loader as paceAndProgressLoader,
} from '@routes/student/$studentId/progress'
import ErrorBoundary, { ErrorComponent } from '@components/error-boundary'
import ExpeditionHome from '@pages/expedition-home'
import Documents from '@pages/documents'
import EmployeeAtendancesRoute from '@routes/employee/attendances'
import EmployeeAttendancesReportRoute from '@routes/employee/attendances/report'
import { loader as employeeAttendancesReportLoader } from '@routes/employee/attendances/report/loader'
import AttendanceFacultyRoute, {
  loader as attendanceFacultyLoader,
} from '@pages/attendance/faculty'
import { StudentTranscriptRoute } from '@routes/employee/students/$studentId/transcript'
import { loader as studentTranscriptLoader } from '@routes/employee/students/$studentId/transcript/loader'
import EnrolmentsRoute from '@routes/admin/enrolments'
import enrolmentsLoader from '@routes/admin/enrolments/loader'
import enrolmentsAction from '@routes/admin/enrolments/action'
import ReleaseProgressReport from '@pages/reports/progress'
import { loader as releaseProgressReportLoader } from '@pages/reports/loader'
import ProgressReports, {
  loader as studentProgressReportsLoader,
} from '@pages/progress-report'
import FeatureFlags from '@pages/feature-flags'
import Permissions from '@pages/permissions'
import EmployeeExpeditionsEditPage from '@routes/employee/expeditions/edit'
import { loader as employeeExpeditionsEditLoader } from '@routes/employee/expeditions/edit/loader'
import EmployeeExpeditionsIndexPage, {
  loader as employeeExpeditionsIndexLoader
} from '@routes/employee/expeditions'
import Facilitate from '@components/facilitate'
import FacilitateExperiences from '@components/facilitate/experiences'
import { loader as facilitateExperiencesStatusLoader } from '@components/facilitate/experiences/loader'
import FacilitateExperiencesType, { loader as facilitateExperiencesTypeLoader } from '@components/facilitate/experiences-type'
import FacilitateTasks from '@components/facilitate/tasks'
import Impersonate, {
  loader as impersonateLoader,
  action as impersonateAction
} from '@routes/employee/impersonate'
import { loader as facilitateTasksLoader } from '@components/facilitate/tasks/loader'
import Assessment, {
  loader as assessmentLoader,
} from '@components/facilitate/assessment'
import AssessmentTask, {
  loader as assessmentTaskLoader,
} from '@components/facilitate/assessment-task'
import AssessmentSubmission from '@components/facilitate/assessment-submission'
import { loader as assessmentSubmissionLoader } from '@components/facilitate/assessment-submission-loader'
import AssessmentFeedback from '@components/facilitate/assessment-feedback'
import { loader as assessmentFeedbackLoader } from '@components/facilitate/assessment-feedback-loader'
import EmployeeRoute from '@routes/employee'
import ExpeditionTasksRoute from '@routes/employee/expeditions/$id/tasks'
import { loader as expeditionsTasksLoader } from '@routes/employee/expeditions/$id/tasks/loader'
import ExpeditionAttendancesRoute from '@routes/employee/expeditions/$id/attendances'
import { loader as expeditionAttendancesLoader } from '@routes/employee/expeditions/$id/attendances/loader'
import ExpeditionRegistrationsRoute from '@routes/employee/expeditions/$id/registrations'
import { loader as expeditionRegistrationsLoader } from '@routes/employee/expeditions/$id/registrations/loader'
import { action as expeditionRegistrationsAction } from '@routes/employee/expeditions/$id/registrations/action'
import ExpeditionIdRoute from '@routes/employee/expeditions/$id/$expeditionId'
import EditExpeditionRoute, { loader as editExpeditionLoader, action as editExpeditionAction } from '@routes/employee/expeditions/$id/edit'
import NewExpeditionRoute, { loader as newExpeditionLoader, action as newExpeditionAction } from '@routes/employee/expeditions/new'
import EmployeeStudentsRoute from '@routes/employee/students'
import { loader as employeeStudentsIndexLoader } from '@routes/employee/students/loader'
import FlutterRoute, { loader as flutterLoader } from '@routes/flutter'
import FlutterLoginRoute, { loader as flutterLoginLoader } from '@routes/flutter/login'
import SwapRequestsRoute from '@routes/employee/expeditions/swap-requests'
import swapRequestsLoader from '@routes/employee/expeditions/swap-requests/loader'
import swapRequestsAction from '@routes/employee/expeditions/swap-requests/action'
import WorkbenchUnitsList, { loader as WorkbenchUnitsListLoader } from '@routes/admin/workbench/units/list'
import WorkbenchUnitsRoute from '@routes/admin/workbench/units/index'
import Admissions, { loader as admissionsLoader, action as admissionsAction } from '@routes/admin/workbench/admissions/admissions'
import EditUnitWorkbenchRoute, { loader as EditUnitWorkbenchLoader, action as EditUnitWorkbenchAction } from '@routes/admin/workbench/units/edit'
import NewUnitWorkbenchRoute, { loader as NewUnitWorkbenchLoader, action as NewUnitWorkbenchAction } from '@routes/admin/workbench/units/new'
import WorkbenchIndex from '@routes/admin/workbench/index'
import ExpeditionTab from '@routes/employee/facilitate/assessment/$themeId/task/$taskId/$studentId/$taskSubmissionId/expedition-tab'
import { loader as expeditionTabLoader } from '@routes/employee/facilitate/assessment/$themeId/task/$taskId/$studentId/$taskSubmissionId/expedition-tab-loader'
import ExpeditionTabMetrics, {
  loader as expeditionTabMetricsLoader,
} from '@routes/employee/facilitate/assessment/$themeId/task/$taskId/$studentId/$taskSubmissionId/metrics/$metric'
import WorkbenchHeartbeatRoute from '@routes/admin/workbench/hearbeat'
import WorkbenchEmployeesRoute from '@routes/admin/workbench/employees'
import WorkbenchEmployeeProfile from '@routes/admin/workbench/employees/profile/index'
import WorkbenchEmployeeNew from '@routes/admin/workbench/employees/profile/new'
import WorkbenchEmployeeTable from '@routes/admin/workbench/employees/table/index'
import WorkbenchMailchimpRoute from '@routes/admin/workbench/mailchimp'
import WorkbenchAdminRoute from '@routes/admin/workbench/admin-actions'
import WorkbenchGoogleRoute, { loader as workbenchGoogleLoader, } from '@routes/admin/workbench/google'
import WildcardRedirectRoute from '@route-wrappers/wildcard-redirect'
import Employee$IdNotificationsRoute from '@routes/employee/$user_id/notifications'
import Employee$UserIdNotifications$NotificationTypeRoute, {
  loader as notificationsTypeLoader,
  action as notificationsTypeAction,
} from '@routes/employee/$user_id/notifications/$notification_type'
import ProgressCycleRoute from '@routes/student/$studentId/progress/cycle'
import { loader as progressCycleLoader } from '@routes/student/$studentId/progress/cycle/loader'
import StudentGraduationRoute, {
  loader as studentGraduationLoader
} from '@routes/student/$studentId/graduation'
import { StudentHouseAttendancesRoute } from '@routes/student/$studentId/houses/$houseId/attendances'
import { StudentHouseAttendanceEventRoute } from '@routes/student/$studentId/houses/$houseId/attendances/$eventId'
import EmployeeStudentContactRoute from '@routes/employee/students/$studentId/contact'
import { loader as employeeStudentContactLoader } from '@routes/employee/students/$studentId/contact/loader'
import ExemptionsEditRoute, {
  loader as exemptionsEditLoader,
  action as exemptionsEditAction,
} from '@routes/employee/students/$studentId/exemptionsEdit'
import NotificationsWorkbenchRoute, {
  action as notificationsWorkbenchAction,
  loader as notificationsWorkbenchLoader,
} from '@routes/admin/workbench/notifications'
import Employee$UserIdNotificationsSettingsRoute, {
  loader as userNotificationSettingsLoader,
  action as userNotificationSettingsAction,
} from '@routes/employee/$user_id/notifications/settings'
import { IndexNoAuthRoute, loader as indexNoAuthLoader } from './routes'
import { NavigateCycle } from '@hooks/useNavigateWithCycle'
import Employee$UserIdNotifications$NotificationTypeDetailsIdRoute, {
  loader as userNotificationDetailsLoader,
} from '@routes/employee/$user_id/notifications/$notification_type/details/$id'
import WorkbenchOnboardingAddFamiliesToGroupRoute, {
  loader as workbenchOnboardingAddFamiliesToGroupLoader,
  action as workbenchOnboardingAddFamiliesToGroupAction,
} from '@routes/admin/workbench/onboarding/families/$campusFilter/add-families-to-group'
import WorkbenchOnboardingCampus$OnboardingGroupIdContentRoute, {
  loader as workbenchOnboardingCampus$OnboardingGroupIdContentLoader,
} from '@routes/admin/workbench/onboarding/$onboardingGroupId/content'
import WorkbenchOnboardingChecklistNewRoute, {
  loader as workbenchOnboardingChecklistNewRouteLoader,
  action as workbenchOnboardingChecklistNewRouteAction
} from '@routes/admin/workbench/onboarding/$onboardingGroupId/content/checklist/new'
import WorkbenchOnboardingGroupsNewRoute, {
  loader as workbenchOnboardingGroupsNewLoader,
  action as workbenchOnboardingGroupsNewAction
} from '@routes/admin/workbench/onboarding/groups/$campusFilter/new'
import WorkbenchOnboardingGroupDuplicatedGroupModal$onboardingIdRoute, {
  loader as workbenchOnboardingGroupDuplicatedGroupModal$onboardingIdLoader
} from '@routes/admin/workbench/onboarding/groups/$campusFilter/duplicated-group-modal/$onboardingGroupId'
import WorkbenchOnboardingChecklistEditRoute, {
  loader as workbenchOnboardingChecklistEditLoader,
  action as workbenchOnboardingChecklistEditAction
} from '@routes/admin/workbench/onboarding/$onboardingGroupId/content/checklist/$checklistId/$userViewType/edit'
import WorkbenchOnboardingGroups$OnboardingGroupIdRoute, {
  loader as workbenchOnboardingGroups$OnboardingGroupIdLoader,
  action as workbenchOnboardingGroups$OnboardingGroupIdAction,
} from '@routes/admin/workbench/onboarding/groups/$campusFilter/edit/$onboardingGroupId'
import WorkbenchGoogleOfficeHoursRoute, {
  loader as workbenchGoogleOfficeHoursLoader,
  action as workbenchGoogleOfficeHoursAction,
} from '@routes/admin/workbench/google/office-hours'
import WorkbenchCannyRoute from '@routes/admin/workbench/canny'
import WorkbenchCannySyncUsersRoute from '@routes/admin/workbench/canny/sync-users'
import { action as workbenchCannySyncUsersAction } from '@routes/admin/workbench/canny/sync-users/action'
import WorkbenchGoogleHouseEventsRoute, { loader as workbenchGoogleHouseEventLoader, action as workbenchGoogleHouseEventAction } from '@routes/admin/workbench/google/house-events'
import WorkbenchGoogleSyncGroupsRoute, { action as workbenchGoogleSyncGroupsAction } from '@routes/admin/workbench/google/sync-groups'
import WorkbenchGoogleForceHolidaysRoute, { action as workbenchGoogleForceHolidaysAction } from '@routes/admin/workbench/google/force-holidays'
import AdminReports, { loader as adminReportsLoader } from '@routes/admin/reports'
import ReportsIndex from '@routes/admin/reports/index'
import StudentsRequests, { loader as studentsRequestsLoader } from '@routes/admin/reports/students/requests'
import RegistrationChangesReport from '@routes/admin/reports/registration-changes'
import { loader as registrationChangesReportLoader } from '@routes/admin/reports/registration-changes/loader'
import ExperiencesRosterReportsRoutes from '@routes/admin/reports/experiences-roster'
import experiencesRosterReportsLoader from '@routes/admin/reports/experiences-roster/loader'
import StudentRoutingNew, { loader as studentRoutingLoader } from '@routes/student/$studentId/index'
import StudentMissingUnits, { loader as studentMissingUnitsLoader } from '@routes/admin/reports/students/missing/units'
import UnitsCompletion, { loader as unitsCompletionLoader } from '@routes/admin/reports/units-completion'
import StudentAvailabilityReport, { loader as studentAvailabilityReportLoader } from '@routes/admin/reports/students/availability'
import MaterialsReportRoute, { loader as materialsReportLoader } from '@routes/admin/reports/materials'
import { HousesIndexRoute } from '@features/houses._index'
import { HouseNewRoute } from '@features/houses.new'
import { HouseRoute } from '@features/houses.$houseId'
import { HouseOverviewRoute } from '@features/houses.$houseId.overview'
import { HouseOverviewReportRoute } from '@features/houses.$houseId.overview.$reportId'
import { HouseOverviewReportNoteDialogRoute } from '@features/houses.$houseId.overview.$reportId.note'
import { HouseStudentsRoute } from '@features/houses.$houseId.students'
import { HouseAttendancesRoute } from '@features/houses.$houseId.attendances'
import { HouseAttendanceEventRoute } from '@features/houses.$houseId.attendances.$eventId'
import { HouseEditRoute } from '@features/houses.edit.$houseId'
import { FeatureFlagSettingsLabsRoute } from '@routes/admin/workbench/feature-flag/$feature_flag_id/sora-labs-settings'
import NotFoundRoute from './route-wrappers/route-not-found'
import { EmployeeStudentWithdrawalRoute } from '@routes/employee/students/$studentId/withdrawal'
import AdmissionsConfig from '@routes/admin/workbench/admissions/config/admissions-config'
import AdmissionsCopyConfig, { loader as admissionsCopyConfigLoader, action as admissionsCopyConfigAction } from '@routes/admin/workbench/admissions/config/admissions-copy-config'
import AdmissionsStartDatesConfig, { loader as admissionsStartDatesConfigLoader, action as admissionsStartDatesConfigAction } from '@routes/admin/workbench/admissions/config/admissions-start-dates-config'
import { RootRoute } from '@features/_root'
import { RootAuthenticatedRoute } from '@features/_root._authenticated'
import { HomeRoute } from '@features/home'
import { FacilitateExperienceTaskRoute } from '@features/facilitate.experiences.$experienceId.tasks.$taskId'
import { FacilitateExperienceAttendanceRoute } from '@features/facilitate_.$type_.$experienceId_.attendance'
import { FacilitateExperienceAttendanceDefaultRoute } from '@features/facilitate_.$type_.$experienceId_.attendance.default'
import { FacilitateExperienceAttendanceDefaultSessionRoute } from '@features/facilitate_.$type_.$experienceId_.attendance.default.$sessionId'
import { FacilitateExperienceAttendanceDefaultSessionZoomReportRoute } from '@features/facilitate_.$type_.$experienceId_.attendance.default.$sessionId.zoom-report'
import { FacilitateExperienceAttendanceAggregatedRoute } from '@features/facilitate_.$type_.$experienceId_.attendance.aggregated'
import { FacilitateExperienceTaskStudentRoute } from '@features/facilitate.experiences.$experienceId.tasks.$taskId.students.$studentId'
import { FacilitateExperienceTaskStudentMetricsRoute } from '@features/facilitate.experiences.$experienceId.tasks.$taskId.students.$studentId.metrics'
import { FacilitateExperienceTaskStudentDeadlineExtensionRoute } from '@features/facilitate.experiences.$experienceId.tasks.$taskId.students.$studentId.deadline-extension'
import { FacilitateExperienceTaskStudentFeedbackRoute } from '@features/facilitate.experiences.$experienceId.tasks.$taskId.students.$studentId.feedback'
import { FacilitateExperienceTaskStudentCommentBankRoute } from '@features/facilitate.experiences.$experienceId.tasks.$taskId.students.$studentId.comment-bank'
import { FacilitateExperienceTaskStudentCommentBankCreateRoute } from '@features/facilitate.experiences.$experienceId.tasks.$taskId.students.$studentId.comment-bank.preset-comment.create'
import { FacilitateExperienceTaskStudentMidCycleReportAiRoute } from '@features/facilitate.experiences.$experienceId.tasks.$taskId.students.$studentId.mid-cycle-report-ai'
import { ImpactDashboardRoute } from '@features/impact-dashboard'
import { NEW_TemplateFormRoute } from '@features/template.($templateId).edit'
import { HouseAttendanceEventZoomReportRoute } from '@features/houses.$houseId.attendances.$eventId.zoom-report'
import { NEW_ExperienceRoute } from '@features/experiences'
import { NEW_ExperienceFormRoute } from '@features/experiences.($experienceId).edit'
import { NEW_ExpeditionTasksRoute } from '@features/experiences.$experienceId.tasks'
import { NEW_ExpeditionRegistrationsRoute } from '@features/experiences.$experienceId.registrations'
import { UsePickedTemplateRoute } from '@features/template.$templateId.use-picked-template'
import { OnboardingGroupsRoute } from '@features/workbench.onboarding.groups'
import { OnboardingGroupsCampusId } from '@features/workbench.onboarding.groups.$campusId'
import { CyclesRoute } from '@features/cycles'
import { EmployeeStudentRoute } from '@features/employee.students.$studentId'
import { EmployeeStudentOverviewRoute } from '@features/employee.students.$studentId.overview'
import { EmployeeStudentNotesRoute } from '@features/employee.students.$studentId.notes'
import { EmployeeStudentLearningAvailabilityRoute } from '@features/employee.students.$studentId.learning-availability'
import { EmployeeStudentNotesFormRoute } from '@features/employee.students.$studentId.notes.form.($noteId)'
import { EmployeeStudentProgramRoute } from '@features/employee.students.$studentId.program.($subjectId)'
import { Onboarding$TodoIdRoute } from '@features/workbench.onboarding.$onboardingGroupId.content.checklist.$checklistId.$userViewType.todo.$todoId'
import { OnboardingUserTypeRoute } from '@features/workbench.onboarding.$onboardingGroupId.content.checklist.$checklistId.$userType'
import { OnboardingChecklistIdRoute } from '@features/workbench.onboarding.$onboardingGroupId.content.checklist.$checklistId'
import { InboxSendbird } from '@features/inbox-sendbird'
import { WorkbenchRoute } from '@features/workbench._index'
import { WorkbenchMathPlacementRoute } from '@features/workbench.math-placement'
import { WorkbenchMathPlacementTimelineRoute } from '@features/workbench.math-placement.timeline'
import { WorkbenchMathPlacementIndexRoute } from '@features/workbench.math-placement._index'
import { WorkbenchMathPlacementStudentsRoute } from '@features/workbench.math-placement.students'
import { WorkbenchOnboardingFamiliesReportRoute } from '@features/workbench.onboarding.families'
import { WorkbenchOnboardingFamiliesReportFilterRoute } from '@features/workbench.onboarding.families.$campusFilter'
import { JourneyBuilderProcessesIndexRoute } from '@features/workbench.journey-builder.processes._index'
import { JourneyBuilderProcessNewRoute } from '@features/workbench.journey-builder.processes.new'
import { JourneyBuilderProcessRoute } from '@features/workbench.journey-builder.processes.$processId'
import { JourneyBuilderProcessIndexRoute } from '@features/workbench.journey-builder.processes.$processId._index'
import { JourneyBuilderStudentsRoute } from '@features/workbench.journey-builder.processes.$processId.students'
import { JourneyBuilderTemplatesConfigRoute } from '@features/workbench.journey-builder.processes.$processId.templates.config'
import { JourneyBuilderTemplatesReviewRoute } from '@features/workbench.journey-builder.processes.$processId.templates.review'
import { JourneyBuilderAssignmentsRoute } from '@features/workbench.journey-builder.processes.$processId.assignments'
import { JourneyBuilderAssignmentsStudentsRoute } from '@features/workbench.journey-builder.processes.$processId.assignments.students'
import { JourneyBuilderAssignmentsExpeditionsRoute } from '@features/workbench.journey-builder.processes.$processId.assignments.expeditions'
import { JourneyBuilderScheduleConfigRoute } from '@features/workbench.journey-builder.processes.$processId.schedule.config'
import { JourneyBuilderScheduleReviewRoute } from '@features/workbench.journey-builder.processes.$processId.schedule.review'
import { JourneyBuilderScheduleReviewStudentsRoute } from '@features/workbench.journey-builder.processes.$processId.schedule.review.students'
import { JourneyBuilderScheduleReviewExpertsRoute } from '@features/workbench.journey-builder.processes.$processId.schedule.review.experts'
import { JourneyBuilderScheduleReviewExpeditionsRoute } from '@features/workbench.journey-builder.processes.$processId.schedule.review.expeditions'
import { JourneyBuilderScheduleGeneratedRoute } from '@features/workbench.journey-builder.processes.$processId.schedule.generated'
import { JourneyBuilderScheduleGeneratedStudentsRoute } from '@features/workbench.journey-builder.processes.$processId.schedule.generated.students'
import { JourneyBuilderScheduleGeneratedExpertsRoute } from '@features/workbench.journey-builder.processes.$processId.schedule.generated.experts'
import { JourneyBuilderScheduleGeneratedExpeditionsRoute } from '@features/workbench.journey-builder.processes.$processId.schedule.generated.expeditions'
import { JourneyBuilderScheduleGeneratedMissingRoute } from '@features/workbench.journey-builder.processes.$processId.schedule.generated.missing'
import { WorkbenchOnboardingRoute } from '@features/workbench.onboarding'
import { FacilitateRoute } from '@features/facilitate'
import { FacilitateTypeRoute } from '@features/facilitate.$type'
import { FacilitateTypeExperienceIdRoute } from '@features/facilitate.$type.$experienceId'
import { WorkbenchPathwaysIndexRoute } from '@features/workbench.pathways._index'
import { WorkbenchPathwaysRoute } from '@features/workbench.pathways'
import { WorkbenchPathwaysManagementIndexRoute } from '@features/workbench.pathways.management._index'
import { WorkbenchPathwaysManagementPathwayIdRoute } from '@features/workbench.pathways.management.$pathwayId'
import { WorkbenchPathwaysStudentsRoute } from '@features/workbench.pathways.students'
import { FacultyAvailabilityRoute } from '@features/faculty-availability.$employeeId'
import { WorkbenchStudentHearbeatServerRoute } from '@features/workbench.student-heartbeat-server'
import { WorkbenchStudentHearbeatServerBasicSetupRoute } from '@features/workbench.student-heartbeat-server.basic-setup'
import { WorkbenchStudentHearbeatServerStudentsRoute } from '@features/workbench.student-heartbeat-server.students'
import { WorkbenchStudentHearbeatServerHousesRoute } from '@features/workbench.student-heartbeat-server.houses'
import { WorkbenchStudentHearbeatServerExperiencesRoute } from '@features/workbench.student-heartbeat-server.experiences'
import { WorkbenchStudentHearbeatServerUsersRoute } from '@features/workbench.student-heartbeat-server.users'
import { WorkbenchStudentHearbeatServerEmployeesRoute } from '@features/workbench.student-heartbeat-server.employees'
import { WorkbenchStudentHearbeatServerJobsRoute } from '@features/workbench.student-heartbeat-server.jobs'
import { WorkbenchStudentHearbeatServerJobsLogsRoute } from '@features/workbench.student-heartbeat-server.jobs.$jobId'
import { CycleIdRoute } from '@features/cycles.$cycleId'
import { WorkbenchGoogleCalendarSyncRoute } from '@features/workbench.google-calendar-sync'
import { WorkbenchRosettaStoneRoute } from '@features/workbench.rosetta-stone'
import { WorkbenchRosettaStoneUpdateCreditRoute } from '@features/workbench.rosetta-stone.update-credit'
import { WorkbenchRosettaStoneManageUsersRoute } from '@features/workbench.rosetta-stone.manage-users'
import { ClubsRoute } from '@features/clubs_'
import { ClubsEditRoute } from '@features/clubs_.($clubId).edit'
import { ClubsMembersRoute } from '@features/clubs_.$clubId.members'

axios.defaults.baseURL = '/api'
axios.defaults.headers.common['X-Frontend-Id'] = import.meta.env.VITE_APP_GITHUB_SHA

function App() {
  const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(
    createBrowserRouter
  )
  const router = sentryCreateBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<RootRoute.Element />} loader={RootRoute.loader} errorElement={<ErrorComponent />}>
        <Route path="/" element={<RootAuthenticatedRoute.Element />} loader={RootAuthenticatedRoute.loader} action={RootAuthenticatedRoute.action}>
          <Route index element={<WildcardRedirectRoute />} />
          <Route path="home" element={<HomeRoute.Element />} loader={HomeRoute.loader} />
          <Route path="facilitate" element={<FacilitateRoute.Element />} loader={FacilitateRoute.loader} >
            <Route path=":type" element={<FacilitateTypeRoute.Element />} loader={FacilitateTypeRoute.loader}>
              <Route path=":experienceId" element={<FacilitateTypeExperienceIdRoute.Element />} loader={FacilitateTypeExperienceIdRoute.loader} />
            </Route>
          </Route>
          <Route path='facilitate/:type/:experienceId/attendance' element={<FacilitateExperienceAttendanceRoute.Element />} loader={FacilitateExperienceAttendanceRoute.loader}>
            <Route path='default' element={<FacilitateExperienceAttendanceDefaultRoute.Element />} loader={FacilitateExperienceAttendanceDefaultRoute.loader}>
              <Route path=':sessionId' element={<FacilitateExperienceAttendanceDefaultSessionRoute.Element />} loader={FacilitateExperienceAttendanceDefaultSessionRoute.loader} action={FacilitateExperienceAttendanceDefaultSessionRoute.action}>
                <Route path="zoom-report" element={<FacilitateExperienceAttendanceDefaultSessionZoomReportRoute.Element />} loader={FacilitateExperienceAttendanceDefaultSessionZoomReportRoute.loader} />
              </Route>
            </Route>
            <Route path='aggregated' element={<FacilitateExperienceAttendanceAggregatedRoute.Element />} loader={FacilitateExperienceAttendanceAggregatedRoute.loader} />
          </Route>
          <Route path='facilitate/:type/:experienceId/tasks/:taskId' element={<FacilitateExperienceTaskRoute.Element />} loader={FacilitateExperienceTaskRoute.loader}>
            <Route path='students/:studentId' element={<FacilitateExperienceTaskStudentRoute.Element />} loader={FacilitateExperienceTaskStudentRoute.loader} action={FacilitateExperienceTaskStudentRoute.action}>
              <Route path='metrics' element={<FacilitateExperienceTaskStudentMetricsRoute.Element />} loader={FacilitateExperienceTaskStudentMetricsRoute.loader} />
              <Route path='deadline-extension' element={<FacilitateExperienceTaskStudentDeadlineExtensionRoute.Element />} loader={FacilitateExperienceTaskStudentDeadlineExtensionRoute.loader} action={FacilitateExperienceTaskStudentDeadlineExtensionRoute.action} />
              <Route path='mid-cycle-report-ai' element={<FacilitateExperienceTaskStudentMidCycleReportAiRoute.Element />} loader={FacilitateExperienceTaskStudentMidCycleReportAiRoute.loader} action={FacilitateExperienceTaskStudentMidCycleReportAiRoute.action} />
              <Route path='feedback' element={<FacilitateExperienceTaskStudentFeedbackRoute.Element />} loader={FacilitateExperienceTaskStudentFeedbackRoute.loader} action={FacilitateExperienceTaskStudentFeedbackRoute.action} errorElement={<ErrorComponent />} />
              <Route path='comment-bank' element={<FacilitateExperienceTaskStudentCommentBankRoute.Element />} loader={FacilitateExperienceTaskStudentCommentBankRoute.loader} action={FacilitateExperienceTaskStudentCommentBankRoute.action} >
                <Route path='preset-comment/create' element={<FacilitateExperienceTaskStudentCommentBankCreateRoute.Element />} action={FacilitateExperienceTaskStudentCommentBankCreateRoute.action} />
              </Route>
            </Route>
          </Route>

          <Route path='inbox-sendbird' element={<InboxSendbird.Element />} loader={InboxSendbird.loader} />

          <Route path="faculty-availability/:employeeId" element={<FacultyAvailabilityRoute.Element />} loader={FacultyAvailabilityRoute.loader} action={FacultyAvailabilityRoute.action} />

          <Route path="employee" element={<EmployeeRoute />} errorElement={<ErrorComponent type="DEFAULT" />} >
            <Route path="*" element={<NotFoundRoute />} />
            <Route path=":user_id/notifications">
              <Route path="settings" element={<Employee$UserIdNotificationsSettingsRoute />} loader={userNotificationSettingsLoader} action={userNotificationSettingsAction} />
              <Route path="view" element={<Employee$IdNotificationsRoute />}>
                <Route path=":notification_type" element={<Employee$UserIdNotifications$NotificationTypeRoute />} loader={notificationsTypeLoader} action={notificationsTypeAction}>
                  <Route path="details/:notification_id" element={<Employee$UserIdNotifications$NotificationTypeDetailsIdRoute />} loader={userNotificationDetailsLoader} />
                </Route>
              </Route>
            </Route>
            <Route path="expeditions">
              <Route index element={<EmployeeExpeditionsIndexPage />} loader={employeeExpeditionsIndexLoader} />
              <Route path="edit" element={<EmployeeExpeditionsEditPage />} loader={employeeExpeditionsEditLoader}>
              </Route>
              <Route path="new" element={<NewExpeditionRoute />} loader={newExpeditionLoader} action={newExpeditionAction} />
              <Route path="swap-requests" element={<SwapRequestsRoute />} loader={swapRequestsLoader} action={swapRequestsAction} />
              <Route path=":id" element={<ExpeditionIdRoute />}>
                <Route path="edit" element={<EditExpeditionRoute />} loader={editExpeditionLoader} action={editExpeditionAction} />
                <Route path="tasks" element={<ExpeditionTasksRoute />} loader={expeditionsTasksLoader} />
                <Route path="registrations" element={<ExpeditionRegistrationsRoute />} loader={expeditionRegistrationsLoader} action={expeditionRegistrationsAction} />
                <Route path="attendances" element={<ExpeditionAttendancesRoute />} loader={expeditionAttendancesLoader} />
              </Route>
            </Route>
            <Route path="students">
              <Route index element={<EmployeeStudentsRoute />} loader={employeeStudentsIndexLoader} />
              <Route path=":student_id" element={<EmployeeStudentRoute.Element />} loader={EmployeeStudentRoute.loader}>
                <Route index element={<NavigateCycle to="overview" />} />
                <Route path="overview" element={<EmployeeStudentOverviewRoute.Element />} loader={EmployeeStudentOverviewRoute.loader} />
                <Route path="contact" element={<EmployeeStudentContactRoute />} loader={employeeStudentContactLoader} />
                <Route path="notes" element={<EmployeeStudentNotesRoute.Element />} loader={EmployeeStudentNotesRoute.loader}>
                  <Route path="form/:noteId?" element={<EmployeeStudentNotesFormRoute.Element />} loader={EmployeeStudentNotesFormRoute.loader} action={EmployeeStudentNotesFormRoute.action} />
                </Route>
                <Route path="program/:subjectId?" element={<EmployeeStudentProgramRoute.Element />} loader={EmployeeStudentProgramRoute.loader} action={EmployeeStudentProgramRoute.action} />
                <Route path="cycle" element={<ProgressCycleRoute />} loader={progressCycleLoader} />
                <Route path="graduation" element={<StudentGraduationRoute />} loader={studentGraduationLoader} />
                <Route path="year" element={<StudentGraduationRoute />} loader={studentGraduationLoader} />
                <Route path="reports" element={<ProgressReports />} loader={studentProgressReportsLoader} />
                <Route path="exemptions" element={<ExemptionsEditRoute />} loader={exemptionsEditLoader} action={exemptionsEditAction} />
                <Route path="withdrawal" element={<EmployeeStudentWithdrawalRoute.Element />} action={EmployeeStudentWithdrawalRoute.action} loader={EmployeeStudentWithdrawalRoute.loader} />
                <Route path="documents" element={<Documents />} />
                <Route path="learning-availability" element={<EmployeeStudentLearningAvailabilityRoute.Element />} loader={EmployeeStudentLearningAvailabilityRoute.loader} action={EmployeeStudentLearningAvailabilityRoute.action} />
                <Route path="transcript" element={<StudentTranscriptRoute />} loader={studentTranscriptLoader} />
              </Route>
            </Route>

            <Route path="facilitate" element={<Facilitate />}>
              <Route path="experiences" element={<FacilitateExperiences />} loader={facilitateExperiencesStatusLoader} >
                <Route path=":type" element={<FacilitateExperiencesType />} loader={facilitateExperiencesTypeLoader}>
                  <Route path=":id" element={<FacilitateTasks />} loader={facilitateTasksLoader} />
                </Route>
              </Route>
              <Route path="assessment/:theme_id/task" element={<Assessment />} loader={assessmentLoader}>
                <Route path=":task_id" element={<AssessmentTask />} loader={assessmentTaskLoader}>
                  <Route path=":student_id" element={<AssessmentSubmission />} loader={assessmentSubmissionLoader}>
                    <Route path="expedition" element={<ExpeditionTab />} loader={expeditionTabLoader}>
                      <Route path="metrics/:metric" element={<ExpeditionTabMetrics />} loader={expeditionTabMetricsLoader} />
                    </Route>
                    <Route path="submissions/:task_submission_id?" element={<AssessmentFeedback />} loader={assessmentFeedbackLoader} errorElement={<ErrorComponent type="DEFAULT" />} />
                  </Route>
                </Route>
              </Route>
            </Route>
            <Route path="attendances" element={<EmployeeAtendancesRoute />}>
              <Route index element={<NavigateCycle to="calendar" />} />
              <Route path="calendar" element={<AttendanceFacultyRoute />} loader={attendanceFacultyLoader} />
              <Route path="report" element={<EmployeeAttendancesReportRoute />} loader={employeeAttendancesReportLoader} />
            </Route>
            <Route path="impersonate" element={<Impersonate />} loader={impersonateLoader} action={impersonateAction} />
          </Route>

          <Route path="workbench" element={<WorkbenchRoute.Element />} loader={WorkbenchRoute.loader} errorElement={<ErrorComponent type="DEFAULT" />}>
            <Route path="*" element={<NotFoundRoute />} />
            <Route index element={<WorkbenchIndex />} />
            <Route path="units" element={<WorkbenchUnitsRoute />}>
              <Route index element={<WorkbenchUnitsList />} loader={WorkbenchUnitsListLoader} />
              <Route path="new" element={<NewUnitWorkbenchRoute />} loader={NewUnitWorkbenchLoader} action={NewUnitWorkbenchAction} />
              <Route path="edit/:unit_id" element={<EditUnitWorkbenchRoute />} loader={EditUnitWorkbenchLoader} action={EditUnitWorkbenchAction} />
            </Route>
            <Route path="heartbeat" element={<WorkbenchHeartbeatRoute />} />
            <Route path="student-heartbeat-server" element={<WorkbenchStudentHearbeatServerRoute.Element />}>
              <Route path="basic-setup" element={<WorkbenchStudentHearbeatServerBasicSetupRoute.Element />} action={WorkbenchStudentHearbeatServerBasicSetupRoute.action} />
              <Route path="students" element={<WorkbenchStudentHearbeatServerStudentsRoute.Element />} loader={WorkbenchStudentHearbeatServerStudentsRoute.loader} action={WorkbenchStudentHearbeatServerStudentsRoute.action} />
              <Route path="houses" element={<WorkbenchStudentHearbeatServerHousesRoute.Element />} loader={WorkbenchStudentHearbeatServerHousesRoute.loader} action={WorkbenchStudentHearbeatServerHousesRoute.action} />
              <Route path="experiences" element={<WorkbenchStudentHearbeatServerExperiencesRoute.Element />} loader={WorkbenchStudentHearbeatServerExperiencesRoute.loader} action={WorkbenchStudentHearbeatServerExperiencesRoute.action} />
              <Route path="users" element={<WorkbenchStudentHearbeatServerUsersRoute.Element />} loader={WorkbenchStudentHearbeatServerUsersRoute.loader} action={WorkbenchStudentHearbeatServerUsersRoute.action} />
              <Route path="employees" element={<WorkbenchStudentHearbeatServerEmployeesRoute.Element />} loader={WorkbenchStudentHearbeatServerEmployeesRoute.loader} action={WorkbenchStudentHearbeatServerEmployeesRoute.action} />
              <Route path="jobs" element={<WorkbenchStudentHearbeatServerJobsRoute.Element />} loader={WorkbenchStudentHearbeatServerJobsRoute.loader}>
                <Route path=":jobId" element={<WorkbenchStudentHearbeatServerJobsLogsRoute.Element />} loader={WorkbenchStudentHearbeatServerJobsLogsRoute.loader} />
              </Route>
            </Route>
            <Route path="rosetta-stone" element={<WorkbenchRosettaStoneRoute.Element />}>
              <Route path="update-credit" element={<WorkbenchRosettaStoneUpdateCreditRoute.Element />} loader={WorkbenchRosettaStoneUpdateCreditRoute.loader} action={WorkbenchRosettaStoneUpdateCreditRoute.action} />
              <Route path="manage-users" element={<WorkbenchRosettaStoneManageUsersRoute.Element />} loader={WorkbenchRosettaStoneManageUsersRoute.loader} action={WorkbenchRosettaStoneManageUsersRoute.action} />
            </Route>

            <Route path="google-calendar-sync" element={<WorkbenchGoogleCalendarSyncRoute.Element />} loader={WorkbenchGoogleCalendarSyncRoute.loader} action={WorkbenchGoogleCalendarSyncRoute.action} />

            <Route path='journey-builder'>
              <Route path='processes'>
                <Route index element={<JourneyBuilderProcessesIndexRoute.Element />} loader={JourneyBuilderProcessesIndexRoute.loader} action={JourneyBuilderProcessesIndexRoute.action} />
                <Route path='new' element={<JourneyBuilderProcessNewRoute.Element />} loader={JourneyBuilderProcessNewRoute.loader} action={JourneyBuilderProcessNewRoute.action} />
                <Route path=':processId' element={<JourneyBuilderProcessRoute.Element />} loader={JourneyBuilderProcessRoute.loader}>
                  <Route index element={<JourneyBuilderProcessIndexRoute.Element />} loader={JourneyBuilderProcessIndexRoute.loader} />
                  <Route path='students' element={<JourneyBuilderStudentsRoute.Element />} loader={JourneyBuilderStudentsRoute.loader} action={JourneyBuilderStudentsRoute.action} />
                  <Route path="templates">
                    <Route path="config" element={<JourneyBuilderTemplatesConfigRoute.Element />} loader={JourneyBuilderTemplatesConfigRoute.loader} action={JourneyBuilderTemplatesConfigRoute.action} />
                    <Route path="review" element={<JourneyBuilderTemplatesReviewRoute.Element />} loader={JourneyBuilderTemplatesReviewRoute.loader} action={JourneyBuilderTemplatesReviewRoute.action} />
                  </Route>
                  <Route path="assignments" element={<JourneyBuilderAssignmentsRoute.Element />} action={JourneyBuilderAssignmentsRoute.action}>
                    <Route path="students" element={<JourneyBuilderAssignmentsStudentsRoute.Element />} loader={JourneyBuilderAssignmentsStudentsRoute.loader} action={JourneyBuilderAssignmentsStudentsRoute.action} />
                    <Route path="expeditions" element={<JourneyBuilderAssignmentsExpeditionsRoute.Element />} loader={JourneyBuilderAssignmentsExpeditionsRoute.loader} action={JourneyBuilderAssignmentsExpeditionsRoute.action} />
                  </Route>
                  <Route path="schedule">
                    <Route path="config" element={<JourneyBuilderScheduleConfigRoute.Element />} loader={JourneyBuilderScheduleConfigRoute.loader} action={JourneyBuilderScheduleConfigRoute.action} />
                    <Route path="review" element={<JourneyBuilderScheduleReviewRoute.Element />} loader={JourneyBuilderScheduleReviewRoute.loader} action={JourneyBuilderScheduleReviewRoute.action}>
                      <Route path="students" element={<JourneyBuilderScheduleReviewStudentsRoute.Element />} loader={JourneyBuilderScheduleReviewStudentsRoute.loader} action={JourneyBuilderScheduleReviewStudentsRoute.action} />
                      <Route path="experts" element={<JourneyBuilderScheduleReviewExpertsRoute.Element />} loader={JourneyBuilderScheduleReviewExpertsRoute.loader} action={JourneyBuilderScheduleReviewExpertsRoute.action} />
                      <Route path="expeditions" element={<JourneyBuilderScheduleReviewExpeditionsRoute.Element />} loader={JourneyBuilderScheduleReviewExpeditionsRoute.loader} action={JourneyBuilderScheduleReviewExpeditionsRoute.action} />
                    </Route>
                    <Route path="generated" element={<JourneyBuilderScheduleGeneratedRoute.Element />} loader={JourneyBuilderScheduleGeneratedRoute.loader}>
                      <Route path="students" element={<JourneyBuilderScheduleGeneratedStudentsRoute.Element />} loader={JourneyBuilderScheduleGeneratedStudentsRoute.loader} />
                      <Route path="experts" element={<JourneyBuilderScheduleGeneratedExpertsRoute.Element />} loader={JourneyBuilderScheduleReviewExpertsRoute.loader} />
                      <Route path="expeditions" element={<JourneyBuilderScheduleGeneratedExpeditionsRoute.Element />} loader={JourneyBuilderScheduleGeneratedExpeditionsRoute.loader} />
                      <Route path="missing" element={<JourneyBuilderScheduleGeneratedMissingRoute.Element />} loader={JourneyBuilderScheduleGeneratedMissingRoute.loader} />
                    </Route>
                  </Route>
                </Route>
              </Route>
            </Route>

            <Route path='pathways' element={<WorkbenchPathwaysRoute.Element />}>
              <Route index element={<WorkbenchPathwaysIndexRoute.Element />} />
              <Route path='management'>
                <Route index element={<WorkbenchPathwaysManagementIndexRoute.Element />} loader={WorkbenchPathwaysManagementIndexRoute.loader} action={WorkbenchPathwaysManagementIndexRoute.action} />
                <Route path=':pathwayId' element={<WorkbenchPathwaysManagementPathwayIdRoute.Element />} loader={WorkbenchPathwaysManagementPathwayIdRoute.loader} action={WorkbenchPathwaysManagementPathwayIdRoute.action} />
              </Route>
              <Route path='students' element={<WorkbenchPathwaysStudentsRoute.Element />} loader={WorkbenchPathwaysStudentsRoute.loader} action={WorkbenchPathwaysStudentsRoute.action} />
            </Route>
            <Route path="employees" element={<WorkbenchEmployeesRoute />} >
              <Route index element={<WorkbenchEmployeeTable />} />
              <Route path=":employee_id" element={<WorkbenchEmployeeProfile />} />
              <Route path="new" element={<WorkbenchEmployeeNew />} />
            </Route>
            <Route path="onboarding" element={<WorkbenchOnboardingRoute.Element />} action={WorkbenchOnboardingRoute.action}>
              <Route path="families" element={<WorkbenchOnboardingFamiliesReportRoute.Element />} loader={WorkbenchOnboardingFamiliesReportRoute.loader}>
                <Route path=":campus_filter" element={<WorkbenchOnboardingFamiliesReportFilterRoute.Element />} loader={WorkbenchOnboardingFamiliesReportFilterRoute.loader} action={WorkbenchOnboardingFamiliesReportFilterRoute.action}>
                  <Route path="add-families-to-group" element={<WorkbenchOnboardingAddFamiliesToGroupRoute />} action={workbenchOnboardingAddFamiliesToGroupAction} loader={workbenchOnboardingAddFamiliesToGroupLoader} />
                </Route>
              </Route>
              <Route path="groups" element={<OnboardingGroupsRoute.Element />} loader={OnboardingGroupsRoute.loader}>
                <Route path=":campusId" element={<OnboardingGroupsCampusId.Element />} loader={OnboardingGroupsCampusId.loader} action={OnboardingGroupsCampusId.action}>
                  <Route path="new" element={<WorkbenchOnboardingGroupsNewRoute />} loader={workbenchOnboardingGroupsNewLoader} action={workbenchOnboardingGroupsNewAction} />
                  <Route path="edit/:onboarding_group_id" element={<WorkbenchOnboardingGroups$OnboardingGroupIdRoute />} loader={workbenchOnboardingGroups$OnboardingGroupIdLoader} action={workbenchOnboardingGroups$OnboardingGroupIdAction} />
                  <Route path="duplicated-group-modal/:onboarding_group_id" element={<WorkbenchOnboardingGroupDuplicatedGroupModal$onboardingIdRoute />} loader={workbenchOnboardingGroupDuplicatedGroupModal$onboardingIdLoader} />
                </Route>
              </Route>
            </Route>
            <Route path="onboarding/:onboarding_group_id/content" element={<WorkbenchOnboardingCampus$OnboardingGroupIdContentRoute />} loader={workbenchOnboardingCampus$OnboardingGroupIdContentLoader}>
              <Route path="checklist">
                <Route path="new" element={<WorkbenchOnboardingChecklistNewRoute />} loader={workbenchOnboardingChecklistNewRouteLoader} action={workbenchOnboardingChecklistNewRouteAction} />
                <Route path=":checklist_id" element={<OnboardingChecklistIdRoute.Element />} loader={OnboardingChecklistIdRoute.loader} action={OnboardingChecklistIdRoute.action}>
                  <Route path=":user_view_type" element={<OnboardingUserTypeRoute.Element />} loader={OnboardingUserTypeRoute.loader} action={OnboardingUserTypeRoute.action}>
                    <Route path="new" element={<WorkbenchOnboardingChecklistNewRoute />} loader={workbenchOnboardingChecklistNewRouteLoader} action={workbenchOnboardingChecklistNewRouteAction} />
                    <Route path="edit" element={<WorkbenchOnboardingChecklistEditRoute />} loader={workbenchOnboardingChecklistEditLoader} action={workbenchOnboardingChecklistEditAction} />
                    <Route path="todo/:todo_id" element={<Onboarding$TodoIdRoute.Element />} loader={Onboarding$TodoIdRoute.loader} action={Onboarding$TodoIdRoute.action} />
                  </Route>
                </Route>
              </Route>
            </Route>
            <Route path="mailchimp" element={<WorkbenchMailchimpRoute />} />
            <Route path="admin-actions" element={<WorkbenchAdminRoute />} />
            <Route path="canny" element={<WorkbenchCannyRoute />}>
              <Route path="sync-users" element={<WorkbenchCannySyncUsersRoute />} action={workbenchCannySyncUsersAction} />
            </Route>
            <Route path="google" element={<WorkbenchGoogleRoute />} loader={workbenchGoogleLoader}>
              <Route path="office-hours" element={<WorkbenchGoogleOfficeHoursRoute />} loader={workbenchGoogleOfficeHoursLoader} action={workbenchGoogleOfficeHoursAction} />
              <Route path="sync-groups" element={<WorkbenchGoogleSyncGroupsRoute />} action={workbenchGoogleSyncGroupsAction} />
              <Route path="force-holidays" element={<WorkbenchGoogleForceHolidaysRoute />} action={workbenchGoogleForceHolidaysAction} />
              <Route path="house-events" element={<WorkbenchGoogleHouseEventsRoute />} loader={workbenchGoogleHouseEventLoader} action={workbenchGoogleHouseEventAction} />
            </Route>
            <Route path="report/progress" element={<ReleaseProgressReport />} loader={releaseProgressReportLoader} />
            <Route path="feature-flags" element={<FeatureFlags />}>
              <Route path=":feature_flag_id/sora-labs-settings" element={<FeatureFlagSettingsLabsRoute.Element />} loader={FeatureFlagSettingsLabsRoute.loader} action={FeatureFlagSettingsLabsRoute.action} />
            </Route>
            <Route path="permissions" element={<Permissions />} />
            <Route path="math-placement" element={<WorkbenchMathPlacementRoute.Element />}>
              <Route index loader={WorkbenchMathPlacementIndexRoute.loader} />
              <Route path="timeline" element={<WorkbenchMathPlacementTimelineRoute.Element />} loader={WorkbenchMathPlacementTimelineRoute.loader} />
              <Route path="students" element={<WorkbenchMathPlacementStudentsRoute.Element />} loader={WorkbenchMathPlacementStudentsRoute.loader} action={WorkbenchMathPlacementStudentsRoute.action} />
            </Route>
            <Route path="enrolments" element={<EnrolmentsRoute />} loader={enrolmentsLoader} action={enrolmentsAction} />
            <Route path="notifications" element={<NotificationsWorkbenchRoute />} loader={notificationsWorkbenchLoader} action={notificationsWorkbenchAction} />
            <Route path="admissions" element={<Admissions />} loader={admissionsLoader} action={admissionsAction} />
            <Route path="config-admissions" element={<AdmissionsConfig />} >
              <Route path="copy" element={<AdmissionsCopyConfig />} loader={admissionsCopyConfigLoader} action={admissionsCopyConfigAction} />
              <Route path="start-dates" element={<AdmissionsStartDatesConfig />} loader={admissionsStartDatesConfigLoader} action={admissionsStartDatesConfigAction} />
            </Route>
          </Route>

          <Route path="reports" element={<AdminReports />} loader={adminReportsLoader}>
            <Route index element={<ReportsIndex />} />
            <Route path="students/requests" element={<StudentsRequests />} loader={studentsRequestsLoader}></Route>
            <Route path="students/missing/units" element={<StudentMissingUnits />} loader={studentMissingUnitsLoader}></Route>
            <Route path="students/availability" element={<StudentAvailabilityReport />} loader={studentAvailabilityReportLoader}></Route>
            <Route path="registration-changes" element={<RegistrationChangesReport />} loader={registrationChangesReportLoader}></Route>
            <Route path="experiences-roster" element={<ExperiencesRosterReportsRoutes />} loader={experiencesRosterReportsLoader}></Route>
            <Route path="units-completion" element={<UnitsCompletion />} loader={unitsCompletionLoader}></Route>
            <Route path="materials" element={<MaterialsReportRoute />} loader={materialsReportLoader}></Route>
          </Route>

          <Route path="NEW_experience" element={<NEW_ExperienceRoute.Element />} loader={NEW_ExperienceRoute.loader} errorElement={<ErrorComponent type="DEFAULT" />}>
            <Route path=":experienceId?/edit" element={<NEW_ExperienceFormRoute />} loader={NEW_ExperienceFormRoute.loader} action={NEW_ExperienceFormRoute.action} />
            <Route path=":experienceId/tasks" element={<NEW_ExpeditionTasksRoute.Element />} loader={NEW_ExpeditionTasksRoute.loader} action={NEW_ExpeditionTasksRoute.action} />
            <Route path=":experienceId/registrations" element={<NEW_ExpeditionRegistrationsRoute />} loader={NEW_ExpeditionRegistrationsRoute.loader} action={NEW_ExpeditionRegistrationsRoute.action} />
          </Route>

          <Route path="template">
            <Route path="library" element={<TemplateExpeditionLibrary />} loader={templateExpeditionLibraryLoader} action={templateExpeditionLibraryAction} />
            <Route path=":templateId?/edit" element={<NEW_TemplateFormRoute />} loader={NEW_TemplateFormRoute.loader} action={NEW_TemplateFormRoute.action} />
            <Route path=":templateId/use-picked-template" element={<UsePickedTemplateRoute.Element />} loader={UsePickedTemplateRoute.loader} action={UsePickedTemplateRoute.action} />
          </Route>

          <Route path="clubs" element={<ClubsRoute.Element />} loader={ClubsRoute.loader} action={ClubsRoute.action} />
          <Route path="clubs/:clubId?/edit" element={<ClubsEditRoute.Element />} loader={ClubsEditRoute.loader} action={ClubsEditRoute.action} />
          <Route path="clubs/:clubId/members" element={<ClubsMembersRoute.Element />} loader={ClubsMembersRoute.loader} action={ClubsMembersRoute.action} />

          <Route path="houses">
            <Route index element={<HousesIndexRoute.Element />} loader={HousesIndexRoute.loader} action={HousesIndexRoute.action} />
            <Route path="new" element={<HouseNewRoute.Element />} loader={HouseNewRoute.loader} action={HouseNewRoute.action} />
            <Route path="edit/:houseId" element={<HouseEditRoute.Element />} loader={HouseEditRoute.loader} action={HouseEditRoute.action} />
            <Route path=":houseId" element={<HouseRoute.Element />} loader={HouseRoute.loader}>
              <Route path="overview" element={<HouseOverviewRoute.Element />} loader={HouseOverviewRoute.loader}>
                <Route path=":reportId" element={<HouseOverviewReportRoute.Element />} loader={HouseOverviewReportRoute.loader} action={HouseOverviewReportRoute.action}>
                  <Route path=":reportItemId/note" element={<HouseOverviewReportNoteDialogRoute.Element />} loader={HouseOverviewReportNoteDialogRoute.loader} action={HouseOverviewReportNoteDialogRoute.action} />
                </Route>
              </Route>
              <Route path="students" element={<HouseStudentsRoute.Element />} loader={HouseStudentsRoute.loader} />
              <Route path="attendances" element={<HouseAttendancesRoute.Element />} loader={HouseAttendancesRoute.loader}>
                <Route path=":eventId" element={<HouseAttendanceEventRoute.Element />} loader={HouseAttendanceEventRoute.loader} action={HouseAttendanceEventRoute.action}>
                  <Route path="zoom-report" element={<HouseAttendanceEventZoomReportRoute.Element />} loader={HouseAttendanceEventZoomReportRoute.loader} />
                </Route>
              </Route>
            </Route>
          </Route>

          <Route path="impact-dashboard" element={<ImpactDashboardRoute.Element />} loader={ImpactDashboardRoute.loader} />

          <Route path="expeditions">
            <Route path=":expedition_id" element={<ExpeditionHome />} />
          </Route>

          <Route path="documents" element={<Documents />} />

          <Route path="cycles" element={<CyclesRoute.Element />} loader={CyclesRoute.loader}>
            <Route path=":cycleId/edit" element={<CycleIdRoute.Element />} loader={CycleIdRoute.loader} action={CycleIdRoute.action} />
          </Route>

        </Route>

        <Route path="flutter" element={<FlutterRoute />} loader={flutterLoader}>
          <Route path="login" element={<FlutterLoginRoute />} loader={flutterLoginLoader} />
          <Route path="student/:student_id" element={<StudentRoutingNew />} loader={studentRoutingLoader} >
            <Route path="houses/:houseId/attendances" element={<StudentHouseAttendancesRoute.Element />} loader={StudentHouseAttendancesRoute.loader}>
              <Route path=":eventId" element={<StudentHouseAttendanceEventRoute.Element />} loader={StudentHouseAttendanceEventRoute.loader} action={StudentHouseAttendanceEventRoute.action} />
            </Route>
            <Route path="progress" element={<PaceAndProgressRoute />} loader={paceAndProgressLoader}>
              <Route index element={<NavigateCycle to="cycle" />} />
              <Route path="cycle" element={<ProgressCycleRoute />} loader={progressCycleLoader} />
              <Route path="graduation" element={<StudentGraduationRoute />} loader={studentGraduationLoader} />
              <Route path="transcript" element={<StudentTranscriptRoute />} loader={studentTranscriptLoader} />
            </Route>
          </Route>
        </Route>

        <Route path="/" element={<IndexNoAuthRoute />} loader={indexNoAuthLoader}>
          <Route index element={<WildcardRedirectRoute />} />
          <Route path="signin" element={<SignInRoute />} loader={signInLoader} />
          <Route path="guardian/signin" element={<GuardianSigninRoute />} loader={guardianSignInLoader} />
          <Route path="password/new" element={<NewPassword />} loader={newPasswordRouteLoader} action={newPasswordRouteAction} />
          <Route path="password/recover" element={<RecoverPassword />} />
        </Route>

        <Route path="*" element={<NotFoundRoute />} />
      </Route >
    )
  )
  return (
    <ErrorBoundary>
      <Toaster />
      <PageTitleProvider>
        <ConfirmModalProvider>
          <CustomSWRConfig>
            <TooltipBase.Provider>
              <RouterProvider router={router} />
            </TooltipBase.Provider>
          </CustomSWRConfig>
        </ConfirmModalProvider>
      </PageTitleProvider>
    </ErrorBoundary>
  )
}

const CustomSWRConfig = ({ children }) => {
  const fetcher = async (url) => {
    const res = await axios.get(url).catch((error) => error.response)
    return res.data
  }
  const onError = (err) => {
    captureException(err)
  }

  return (
    <SWRConfig value={{ fetcher, onError }}>
      {children}
    </SWRConfig>
  )
}

CustomSWRConfig.propTypes = {
  children: PropTypes.any,
}

export default Sentry.withProfiler(App)
