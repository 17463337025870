import React from 'react'
import { Button, Combobox, useFetcher } from '@design-system'

export const StudentAdd = () => {
  const fetcher = useFetcher()
  const isLoading = fetcher.state !== 'idle'

  const handleCancel = (e) => {
    e.preventDefault()
    const formData = new FormData()
    formData.append('_action', 'cancel')
    fetcher.submit(formData, { method: 'post' })
  }

  if (fetcher.data?.students) {
    return (
      <fetcher.Form method="post">
        <input type="hidden" name="_action" value="add_student" />
        <div className={`flex flex-col w-1/3 min-h-min border rounded mb-2 space-y-3 p-2`}>
          <p className="font-bold">Student to add:</p>
          <input type="text" name="notes" className={`mb-2 py-1 rounded pr-8 bg-white`} placeholder="Notes on registration change" />
          <Combobox name='student_id' placeholder='Select a student' loading={isLoading} search={<Combobox.Search placeholder="Search a student" />} >
            {fetcher.data.students.map(student => {
              return (
                <Combobox.Item key={`opt_student_${student.id}`} value={student.id}>
                  {student.name}
                </Combobox.Item>
              )
            })}
          </Combobox>
          <div className="flex items-center space-x-3 justify-end">
            <Button size="xs" variant="outlined" onClick={handleCancel}>Cancel</Button>
            <Button size="xs" type="submit">Add</Button>
          </div>
        </div>
      </fetcher.Form>
    )
  }

  return (
    <fetcher.Form method="get" className='mb-2'>
      <input type="hidden" name="_action" value="get_available_students" />
      <Button size="xs" loading={isLoading} disabled={isLoading}>Add student</Button> 
    </fetcher.Form>
  )
}
