import React from 'react'
import axios from 'axios'
import { useLoaderData, useRevalidator } from 'react-router-dom'
import serializeFormData from '@utils/serializeFormData'
import { OrphanEventRemovalSync } from './orphan-event-removal-sync'

const loader = async ({ params, request }) => {
  const searchParams = new URL(request.url).searchParams
  const action = searchParams.get('_action') || 'default'
  const cycleId = searchParams.get('cycle_id')

  const result = await axios.get('/backoffice/workbench/google-calendar-sync',  { params: { action, cycleId } })
  return result?.data
}

const action = async ({ request }) => {
  const formData = await request.formData()
  const serializedFormData = serializeFormData(formData)
  const action = serializedFormData._action

  try {
    await axios.post(`/backoffice/workbench/google-calendar-sync`, { ...serializedFormData, action })
  } catch (error) {
    return {
      toast: { message: 'There was an error. If the problem persists, contact our tech support team.', appearance: 'error' }
    }
  }

  return null
}

const Element = () => {
  const revalidator = useRevalidator()
  const { workingJobs = [], cycleId } = useLoaderData()

  if (workingJobs.length > 0) {
    setTimeout(() => {
      revalidator.revalidate()
    }, 10000)
  }


  return (
    <div className='flex flex-col min-w-fit'>
      <OrphanEventRemovalSync cycleId={cycleId} isProcessing={workingJobs.includes('REMOVE_ORPHANS_SYNC')} />
    </div>
  )
}

export const WorkbenchGoogleCalendarSyncRoute = {
  loader,
  action,
  Element,
}
