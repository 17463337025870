import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useRevalidator } from 'react-router-dom'
import axios from 'axios'

import useCountdown from '@hooks/useCountdown'
import AttendanceTable from '@components/attendance-table'
import useAttendances from '@hooks/useAttendances'
import { useThisPageTitle } from '@hooks/usePageTitle'
import useQuery from '@hooks/useQuery'
import add from 'date-fns/add'

export function SummarizedExpeditionAttendances({ expedition }) {
  const revalidator = useRevalidator()
  const [queryParams, setQueryParams] = useQuery()


  const setSelectedAttendable = attendable_id => setQueryParams({ ...queryParams, attendable_id })

  const refreshExperiences = revalidator.revalidate

  const [selectedSection, setSelectedSection] = useState(expedition.sessions[0]?.section_id || 0)

  const sessionClosestToToday = expedition.sessions
    .filter(session => session.section_id === selectedSection)
    .find(session => getSessionInterval(session)[1] > new Date(Date.now()))
  const selectedAttendable = (queryParams.attendable_id && Number(queryParams.attendable_id)) || sessionClosestToToday?.attendable_id || expedition.sessions[expedition.sessions.length - 1]?.attendable_id

  useThisPageTitle(`${expedition?.title || 'Expedition'} - Attendances`, { backTo: '/employee/expeditions/edit' })

  const getSessionFromAttendable = attendableId => !expedition || expedition?.sessions.length === 0 ? null : expedition?.sessions?.find(session => session.attendable_id === attendableId)
  const currentSession = getSessionFromAttendable(selectedAttendable)
  const automatedSessionConnected = currentSession?.meeting_started_at && !currentSession?.meeting_ended_at
  const sessionFinished = !!currentSession?.meeting_ended_at
  const isAutomated = currentSession?.attendance_type === 'zoom_automatic'

  const {
    setAttendanceForKey,
    getCheckedFor,
    getPendingFor,
    getDisabledFor,
    getStudentStatus,
    statuses,
  } = useAttendances({ attendable_id: selectedAttendable, activateRefresh: isAutomated })

  if (!selectedAttendable || expedition.sessions.length === 0 || !currentSession) return null

  const forceAction = (action) => async (event) => {
    event.target.disabled = true
    await axios.put(`/attendances/session/${selectedAttendable}?action=${action}`)
    setTimeout(async () => {
      await refreshExperiences()
      event.target.disabled = false
    }, 1000)
  }

  const sections = Array.from(new Set(expedition.sessions.map(session => session.section_id)))
  const sessions = expedition.sessions.sort((s1, s2) => s1.timestamptz > s2.timestamptz).filter(session => session.section_id === selectedSection)

  const students = expedition.students.filter(student => student.section_id === selectedSection)

  const isAttendableDue = attendableId => {
    const [, sessionEnd] = attendableId ? getSessionInterval(currentSession) : []
    return attendableId && sessionEnd < new Date(Date.now())
  }

  return (
    <div className="pb-8">
      <ul className={'flex flex-row justify-center space-x-4 mb-4'}>
        {sections.length > 1 && sections.map((section_id, index) =>
          <li key={section_id}>
            <button className={`uppercase font-bold border-2 border-blue-70 p-2 rounded
              ${selectedSection === section_id ? 'bg-blue-70 text-white' : 'bg-white text-blue-70'}
            `} onClick={() => setSelectedSection(section_id)}>
              <h3>Group {index + 1}</h3>
            </button>
          </li>
        )}
      </ul>
      <ul className={'grid grid-cols-fr'} style={{ gridTemplateColumns: `repeat(${sessions.length}, minmax(0, 1fr))` }}>
        {sessions.map(session =>
          <li key={session.attendable_id}>
            <button className={`
              border w-full h-full p-2 text-center text-xs font-bold relative 
              ${selectedAttendable === session.attendable_id ? 'bg-white border-gray-10' : 'border-transparent'}
            `} style={{
                borderBottomColor: 'transparent',
                bottom: '-1px',
              }} onClick={() => setSelectedAttendable(session.attendable_id)}>
              <h3 className={`${isAttendableDue(session.attendable_id) && 'opacity-50'}`}>
                {new Date(session.timestamptz).toLocaleTimeString('en-US', {
                  weekday: 'short',
                  month: 'short',
                  day: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit',
                })}
              </h3>
            </button>
          </li>
        )}
      </ul>
      <div className="bg-white border p-2">
        {isAutomated &&
          <div>
            <div className='text-center border-b border-b-gray-10 p-2 flex flex-col'>
              <div>
                <span className='text-blue-30'>This session is automated</span>
                {currentSession?.zoom_start_url && <a href={currentSession?.zoom_start_url} target='_blank' className='text-white rounded-md bg-green-50 font-bold py-1 px-2 ml-2' rel="noreferrer">Join Meeting</a>}
              </div>
              {currentSession?.conference_url && <span className='pt-2 text-sm'>Conference Url: {currentSession?.conference_url}</span>}
            </div>
            <div className='flex flex-row items-center'>
              {!automatedSessionConnected && <img src="/assets/meetings/zoom-disconnected.png" alt="zoom-disconnected" className='h-20' />}
              {automatedSessionConnected && <img src="/assets/meetings/zoom-connected.png" alt="zoom-connected" className='h-20' />}
              <div className='flex-auto'></div>
              <div>
                {!automatedSessionConnected &&
                  <button onClick={forceAction('force_start')} className='bg-white rounded-md border-2 border-green-90 text-green-90 disabled:opacity-25 active:text-white active:bg-green-90 font-bold p-2'>Force Start</button>
                }
                {automatedSessionConnected &&
                  <button onClick={forceAction('force_end')} className='bg-white rounded-md border-2 border-danger-60 text-danger-60 disabled:opacity-25 active:text-white active:bg-danger-60 font-bold p-2'>Force Stop</button>
                }
              </div>
            </div>
          </div>
        }
        <form>
          <AttendanceTable
            statuses={statuses}
            students={students}
            attendable_id={selectedAttendable}
            getCheckedFor={getCheckedFor}
            getPendingFor={getPendingFor}
            getDisabledFor={getDisabledFor}
            getStudentStatus={getStudentStatus}
            onChange={setAttendanceForKey}
            isAutomated={isAutomated}
            sessionFinished={sessionFinished}
          />
        </form>
      </div>
    </div>
  )
}
SummarizedExpeditionAttendances.propTypes = { expedition: PropTypes.object }

const ParentsMessageButton = ({ absencesCount, session, onClick, disabled }) => {
  const interval = getSessionInterval(session)
  const [start] = interval
  const meetingStartedAt = session.meeting_started_at ? new Date(session.meeting_started_at + 'Z') : null
  const sessionStartAt = new Date(start)
  const fairStart = meetingStartedAt && meetingStartedAt > sessionStartAt ? meetingStartedAt : sessionStartAt
  const sessionStarted = fairStart < new Date(Date.now())
  const timeAllowedSendMessage = sessionStarted ? add(fairStart, { minutes: 5 }) : null

  const { hours, minutes, seconds } = useCountdown(timeAllowedSendMessage)

  const loading = seconds === undefined
  const zeroMessages = absencesCount === 0

  const numericParentsLabel = `${absencesCount} parent${absencesCount === 1 ? '' : 's'}`

  const countdownDone = seconds + minutes + hours <= 0
  const disableBtn = zeroMessages || loading || !sessionStarted || session.sms_sent_at || !countdownDone || disabled

  return (
    <button
      disabled={disableBtn}
      className={`${disableBtn ? 'bg-gray-40 border border-gray-40 cursor-not-allowed' : 'bg-orange-40 border-orange-40'} border rounded-lg text-xs font-bold text-white uppercase px-8 py-1 w-1/2`}
      onClick={onClick}
    >
      {!sessionStarted ? 'Can\'t message parents before session start'
        : session.sms_sent_at ? `${absencesCount} parents notified at ${new Date(session.sms_sent_at).toLocaleTimeString([], { day: 'numeric', month: 'short' })}`
          : zeroMessages ? 'No absents, Yay!'
            : loading ? 'Loading...'
              : !countdownDone ? `Release to message absences to ${numericParentsLabel} in ${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`
                : `Notify ${numericParentsLabel}`
      }
    </button>
  )
}
ParentsMessageButton.propTypes = {
  absencesCount: PropTypes.number,
  session: PropTypes.shape({
    sms_sent_at: PropTypes.any,
    meeting_started_at: PropTypes.any
  }),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
}

const getSessionInterval = (session) => {
  let sessionStart = new Date(session.timestamptz)
  let sessionEnd = new Date(sessionStart.getTime())
  const [hours, minutes] = session.duration.split(':').map(Number)
  sessionEnd.setHours(sessionEnd.getHours() + hours)
  sessionEnd.setMinutes(sessionEnd.getMinutes() + minutes)
  return [sessionStart, sessionEnd]
}
