import * as React from 'react'
import axios from 'axios'
import { Button, Card, Typography, useFetcher } from '@design-system'
import { ActionFunctionArgs, LoaderFunctionArgs, useLoaderData } from 'react-router-dom'
import serializeFormData from '@utils/serializeFormData'

async function action({ request }: ActionFunctionArgs) {
  const formData = await request.formData()
  const serializedFormData = serializeFormData(formData)
  serializedFormData.ignore_archived_themes = serializedFormData.ignore_archived_themes === 'on'
  serializedFormData.ignore_students_left_sora = serializedFormData.ignore_students_left_sora === 'on'
  const res = await axios.post('/backoffice/workbench/rosetta-stone/update-credit', serializedFormData)
  return res.data
}

async function loader({ request }: LoaderFunctionArgs) {
  const cycle_id = new URL(request.url).searchParams.get('cycle_id')
  const resp = await axios.get('/backoffice/workbench/rosetta-stone/update-credit?cycle_id=' + cycle_id)
  return resp.data
}

interface LoaderData {
  cycle: {
    id: number,
    title: string,
  },
  experience: {
    id: number,
    title: string,
  },
  totalExperiences: number,
  canSync: boolean,
  rosettaLanguageTable: Array<{
    language: string,
    code: string,
    instance: string,
    units: number,
  }>,
}

function Element() {
  const loaderData = useLoaderData() as LoaderData
  const fetcher = useFetcher()
  const loading = fetcher.state === 'loading' || fetcher.state === 'submitting'
  const creditConversionTable = [
    { milestoneScore: '0 - 49%', credit: 0.5 },
    { milestoneScore: '50-59%', credit: 1.0 },
    { milestoneScore: '60-64%', credit: 1.5 },
    { milestoneScore: '65-74%', credit: 2.0 },
    { milestoneScore: '75-79%', credit: 2.5 },
    { milestoneScore: '80-89%', credit: 3.0 },
    { milestoneScore: '90-94%', credit: 3.5 },
    { milestoneScore: '95-100%', credit: 4.0 },
  ]
  const unitsConversionTable = [
    { rosettaUnit: 'RS Unit 1', soraUnits: 'Sora Language Unit 1.1' },
    { rosettaUnit: 'RS Unit 2', soraUnits: 'Sora Language Unit 1.2 and 1.3 (will have the same mastery level)' },
    { rosettaUnit: 'RS Unit 3', soraUnits: 'Sora Language Unit 1.4' },
    { rosettaUnit: 'RS Unit 4', soraUnits: 'Sora Language Unit 1.5 and 1.6 (will have the same mastery level)' },
    { rosettaUnit: 'RS Unit 5', soraUnits: 'Sora Language Unit 2.1' },
    { rosettaUnit: 'RS Unit 6', soraUnits: 'Sora Language Unit 2.2 and 2.3 (will have the same mastery level)' },
    { rosettaUnit: 'RS Unit 7', soraUnits: 'Sora Language Unit 2.4' },
    { rosettaUnit: 'RS Unit 8', soraUnits: 'Sora Language Unit 2.5 and 2.6 (will have the same mastery level)' },
    { rosettaUnit: 'RS Unit 9', soraUnits: 'Sora Language Unit 3.1' },
    { rosettaUnit: 'RS Unit 10', soraUnits: 'Sora Language Unit 3.2 and 3.3 (will have the same mastery level)' },
    { rosettaUnit: 'RS Unit 11', soraUnits: 'Sora Language Unit 3.4' },
    { rosettaUnit: 'RS Unit 12', soraUnits: 'Sora Language Unit 3.5 and 3.6 (will have the same mastery level)' },
    { rosettaUnit: 'RS Unit 13', soraUnits: 'Sora Language Unit 4.1' },
    { rosettaUnit: 'RS Unit 14', soraUnits: 'Sora Language Unit 4.2 and 4.3 (will have the same mastery level)' },
    { rosettaUnit: 'RS Unit 15', soraUnits: 'Sora Language Unit 4.4' },
    { rosettaUnit: 'RS Unit 16', soraUnits: 'Sora Language Unit 4.5 and 4.6 (will have the same mastery level)' },
    { rosettaUnit: 'RS Unit 17', soraUnits: 'Sora Language Unit 5.1' },
    { rosettaUnit: 'RS Unit 18', soraUnits: 'Sora Language Unit 5.2 and 5.3 (will have the same mastery level)' },
    { rosettaUnit: 'RS Unit 19', soraUnits: 'Sora Language Unit 5.4' },
    { rosettaUnit: 'RS Unit 20', soraUnits: 'Sora Language Unit 5.5 and 5.6 (will have the same mastery level)' },
  ]

  return (
    <div className="flex flex-col gap-4 px-6">
      {loaderData.totalExperiences !== 1 && (
        <Card className="bg-danger-10 border border-danger-30 text-danger-90 dark:bg-danger-90 dark:border-danger-60">
          <Card.Content>
            <Typography variant="callout" color="primary">
              <p>There must be exactly one Experience of type <b>Language Learning Goal</b> on <b>{loaderData.cycle.title}</b> in order to run this sync, but there currently are <b>{loaderData.totalExperiences}</b>.</p>
            </Typography>
          </Card.Content>
        </Card>
      )}
      <Card>
        <Card.Content>
          <Typography variant="callout" color="secondary" className="flex flex-col gap-2">
            <p>This sync will update the world language credits based on student progress in Rosetta Stone.</p>
            <p>It will affect all active students that are:</p>
            <ul className="list-disc ml-6">
              <li>Enrolled in Rosetta Stone</li>
              <li>Enrolled on the Experience <b>{loaderData.experience.title} (ID={loaderData.experience.id})</b> on <b>{loaderData.cycle.title}</b></li>
            </ul>
            <p className="mt-2">Below we have more explanations about how Rosetta Stone is used at Sora.</p>
            <p>
              A technical overview of the integration can be
              <a className="ml-1 text-blue-50 underline" href="https://miro.com/app/board/uXjVNzdMOk8=/" target="_blank" rel="noreferrer">
                found here
              </a>.
            </p>
            <ul className="list-disc">
              <p>Integration logs are sent on slack:</p>
              <li className="ml-6">On the <b>#rosetta-stone-integrations</b> channel for production environment syncs</li>
              <li className="ml-6">On the <b>#rosetta-stone-integrations-test</b> channel for development or environment syncs</li>
            </ul>
          </Typography>
        </Card.Content>
        <fetcher.Form method="POST" className="px-4 flex flex-col gap-4 text-sm">
          <input type="hidden" name="cycle_id" value={loaderData.cycle.id} />
          <div className="flex items-center gap-2">
            <input
              id="ignore_archived_themes"
              type="checkbox"
              name="ignore_archived_themes"
              disabled={!loaderData.canSync}
              className="rounded disabled:opacity-30"
            />
            <label htmlFor="ignore_archived_themes">
              Ignore archived themes
            </label>
          </div>

          <div className="flex items-center gap-2">
            <input
              id="ignore_students_left_sora"
              type="checkbox"
              name="ignore_students_left_sora"
              disabled={!loaderData.canSync}
              className="rounded disabled:opacity-30"
            />
            <label htmlFor="ignore_students_left_sora">
              Ignore students left Sora
            </label>
          </div>

          <Button disabled={!loaderData.canSync} type="submit" loading={loading}>Run credit sync for {loaderData.cycle.title}</Button>
        </fetcher.Form>
      </Card>
      <Card>
        <Card.Content className="flex flex-col gap-4">
          <Typography variant="heading-6" weight="bold">
            Unit mapping - Rosetta Stone Units to Sora Units
          </Typography>
          <Typography variant="callout" color="secondary" className="flex flex-col">
            <p>Sora Language Topics are composed of 6 units and are worth 1 credit each.</p>
            <p>We also consider that 4 completed Rosetta Stone Units are worth 1 credit in Sora Home.</p>
            <p>Because of that, Rosetta Stone and Sora Units are not mapped 1:1, so we use the following conversion table:</p>
          </Typography>
          <table className='text-center mb-2 w-full'>
            <thead className="text-xs">
              <tr className="bg-white dark:bg-gray-90">
                <th className="top-0 w-32 sticky px-4 py-2 border border-gray-30 dark:border-gray-90">Rosetta Stone Unit</th>
                <th className="top-0 w-32 sticky px-4 py-2 border border-gray-30 dark:border-gray-90">Sora Home Units</th>
              </tr>
            </thead>
            <tbody className="text-xs">
              {unitsConversionTable.map(({ rosettaUnit, soraUnits }, i) => (
                <tr key={i}>
                  <td className="bg-white dark:bg-gray-90 px-4 py-2 border border-gray-30 dark:border-gray-90">{rosettaUnit}</td>
                  <td className="bg-white dark:bg-gray-90 px-4 py-2 border border-gray-30 dark:border-gray-90">{soraUnits}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Card.Content>
      </Card>
      <Card>
        <Card.Content className="flex flex-col gap-4">
          <Typography variant="heading-6" weight="bold">
            Conversion table - Rosetta Stone Milestone Scores to Sora Credit
          </Typography>
          <Typography variant="callout" color="secondary" className="flex flex-col">
            <p>In order to earn a credit in Sora Home, the student must complete the Milestone activity in Rosetta Stone.</p>
            <p>Based on the score achieved in the Milestone, the student will earn a credit in Sora Home.</p>
            <p>The criteria is the following:</p>
          </Typography>
          <table className='text-center mb-2 w-full'>
            <thead className="text-xs">
              <tr className="bg-white dark:bg-gray-90">
                <th className="top-0 w-32 sticky px-4 py-2 border border-gray-30 dark:border-gray-90">Rosetta Stone Unit Milestone Score</th>
                <th className="top-0 w-32 sticky px-4 py-2 border border-gray-30 dark:border-gray-90">Sora Home Unit Credit</th>
              </tr>
            </thead>
            <tbody className="text-xs">
              {creditConversionTable.map(({ milestoneScore, credit }) => (
                <tr key={milestoneScore}>
                  <td className="bg-white dark:bg-gray-90 px-4 py-2 border border-gray-30 dark:border-gray-90">{milestoneScore}</td>
                  <td className="bg-white dark:bg-gray-90 px-4 py-2 border border-gray-30 dark:border-gray-90">{credit}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Card.Content>
      </Card>
      <Card>
        <Card.Content className="flex flex-col gap-4">
          <Typography variant="heading-6" weight="bold">
            Rosetta Stone Languages
          </Typography>
          <table className='text-center mb-2 w-full'>
            <thead className="text-xs">
              <tr className="bg-white dark:bg-gray-90">
                <th className="top-0 w-32 sticky px-4 py-2 border border-gray-30 dark:border-gray-90">Language</th>
                <th className="top-0 w-32 sticky px-4 py-2 border border-gray-30 dark:border-gray-90">Code</th>
                <th className="top-0 w-32 sticky px-4 py-2 border border-gray-30 dark:border-gray-90">Units</th>
                <th className="top-0 w-32 sticky px-4 py-2 border border-gray-30 dark:border-gray-90">Instance</th>
              </tr>
            </thead>
            <tbody className="text-xs">
              {loaderData.rosettaLanguageTable.map(({ language, code, instance, units }, i) => (
                <tr key={i}>
                  <td className="bg-white dark:bg-gray-90 px-4 py-2 border border-gray-30 dark:border-gray-90">{language}</td>
                  <td className="bg-white dark:bg-gray-90 px-4 py-2 border border-gray-30 dark:border-gray-90">{code}</td>
                  <td className="bg-white dark:bg-gray-90 px-4 py-2 border border-gray-30 dark:border-gray-90">{units}</td>
                  <td className="bg-white dark:bg-gray-90 px-4 py-2 border border-gray-30 dark:border-gray-90">{instance}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Card.Content>
      </Card>
    </div >
  )
}

export const WorkbenchRosettaStoneUpdateCreditRoute = { loader, action, Element }
