import React, { useState } from 'react'
import axios from 'axios'
import { useLoaderData, Link } from 'react-router-dom'
import { toast } from 'sonner'
import useClipboard from '@hooks/useClipboard'
import SoraLink from '@components/link'
import { Button, Icon, unstable_Tooltip as Tooltip } from '@design-system'
import { StudentDetach } from './student-detach'
import { StudentAdd } from './student-add'
import serializeFormData from '@utils/serializeFormData'

type LoaderData = Awaited<ReturnType<typeof loader>>

async function loader({ request, params }) {
  const { experienceId } = params
  const searchParams = new URL(request.url).searchParams
  const action = searchParams.get('_action') || 'default'

  const result = await axios.get(`/backoffice/experiences/${experienceId}/registrations`, { params: { action } })
  return result?.data
}

async function action({ params, request }) {
  const { experienceId } = params
  const formData = await request.formData()
  const serializedFormData = serializeFormData(formData)
  const action = serializedFormData._action

  if (action === 'cancel') return null

  try {
    const { data } = await axios.post(`/backoffice/experiences/${experienceId}/registrations`, { ...serializedFormData, action })
    return data
  } catch (error) {
    return {
      toast: { message: 'There was an unexpected error. If the problem persists, contact our tech support team.', appearance: 'error' }
    }
  }
}

export function NEW_ExpeditionRegistrationsRoute() {
  const [, copy] = useClipboard()

  const { students, canManageRegistrations, canAddStudents } = useLoaderData() as LoaderData

  function copyTableContentToClipboard() {
    try {
      const output = ['Student Name\tStudent Email\tGuardian Name\tGuardian Email']
      for (const student of students) {
        output.push([
          student.name,
          student.email,
          student.guardian_name, 
          student.guardian_email || ''
        ].join('\t'))
      }
      copy(output.join("\n"))
      toast.success('Table Content Copied!')
    } catch (e) {
      toast.error('Something went wrong')
    }
  }

  function copyStudentsToClipboard(prop) {
    try {
      copy(students.map(s => s[prop]).join("\n"))
      toast.success('Content Copied!')
    } catch (e) {
      toast.error('Something went wrong')
    }
  }

  return (
    <div className="flex flex-col">
      <span className="ml-auto text-blue-40 font-bold hover:cursor-pointer mb-1" onClick={copyTableContentToClipboard} data-testid="copy-table-content-btn">
        Export
      </span>
      {canAddStudents && <StudentAdd />}
      <table className="table-auto w-full mb-2" data-testid="registration-table">
        <thead className="bg-white">
          <tr>
            <th className="border px-4 py-2 space-x-2">
              <span>Name</span>
              <Button onClick={() => copyStudentsToClipboard('name')} variant='ghost' size='xs' data-testid="copy-student-names-btn">
                <Icon name='copy' size='xs' title='Copy Student Names' />
              </Button>
            </th>
            <th className="border px-4 py-2 space-x-2">
              <span>Email</span>
              <Button onClick={() => copyStudentsToClipboard('email')} variant='ghost' size='xs' data-testid="copy-student-emails-btn">
                <Icon name='copy' size='xs' title='Copy Student E-mails' />
              </Button>
            </th>
            <th className="border px-4 py-2 space-x-2">
              <span>Guardian</span>
              <Button onClick={() => copyStudentsToClipboard('guardian_name')} variant='ghost' size='xs' data-testid="copy-guardian-emails-btn">
                <Icon name='copy' size='xs' title='Copy Guardian Name' />
              </Button>
            </th>
            {canManageRegistrations && <th className="border px-4 py-2"></th>}
          </tr>
        </thead>
        <tbody className="bg-white">
          {students.map((student, i) => {
            return (
              <tr key={student.id} data-testid="registration-table-row" className={`hover:bg-alpha/10`}>
                <td className="border px-4 py-2" data-testid={`registration-table-row-${i}-student-name`}>
                  <div className='flex flex-row gap-2 items-center'>
                    <SoraLink
                      as={Link}
                      to={`/employee/students/${student.id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="hover:underline"
                    >
                      {student.name}
                    </SoraLink>
                    {student.unavailability && 
                      <Tooltip content={student.unavailability.join('\n')}>
                        <div className="bg-warning-10 font-bold px-2 py-1 rounded text-sm">Unavailable</div>
                      </Tooltip>
                    }
                    {student.conflicts && 
                      <Tooltip content={student.conflicts.join('\n')}>
                        <div className="bg-danger-10 font-bold px-2 py-1 rounded text-sm">Conflict</div>
                      </Tooltip>
                    }
                  </div>
                </td>
                <td className="border px-4 py-2" data-testid={`registration-table-row-${i}-student-email`}>{student.email}</td>
                <td className="border px-4 py-2 space-x-1" data-testid={`registration-table-row-${i}-guardian-data`}>
                  <span>{student.guardian_name || ''}</span>
                  <span>-</span>
                  <span
                    className="py-1 px-2 bg-gray-10 cursor-pointer rounded"
                    onClick={() => {
                      copy(student.guardian_email)
                      toast.success('Value Copied!')
                    }}
                  >
                    {student.guardian_email}
                    <Icon name='copy' size='sm' title='Copy Guardian Email' />
                  </span>
                </td>
                {canManageRegistrations && (
                  <td className="border py-2 text-center" data-testid={`registration-table-row-${i}-remove-btn`}>
                    <StudentDetach studentId={student.id} />
                  </td>
                )}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

NEW_ExpeditionRegistrationsRoute.loader = loader
NEW_ExpeditionRegistrationsRoute.action = action
